import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import styles from "./HarvestForecast.module.css";

import { Autocomplete, ThemeProvider, Typography, createTheme } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import InputData from "../../../components/InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { CropSeasonService } from "../../../service/crop_season_service";
import { HarvestService } from "../../../service/harvest_service";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "stretch",
	justifyContent: "stretch",
	widths: "auto",
	m: 0,
	p: 0,
};

function HarvestForecast() {
	const [searchParams] = useSearchParams();
	const [selectedSafra, setSelectedSafra] = useState(null);
	const [allSafras, setAllSafras] = useState([]);
	const [oldCropSeasonId, setOldCropSeasonId] = useState("");
	const [id, setId] = useState(null);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [quantidadeTotal, setQuantidadeTotal] = useState([]);
	const [errorMessageDateCad, setErrorMessageDateCad] = useState(false);
	const [errorMessageSelectSafras, setErrorMessageSelectSafras] =
		useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [unid, setUnid] = useState("");
	/* const [embalagemId, setEmbalagemId] = useState(""); */

	const cropSeasonService = new CropSeasonService();
	const harvestService = new HarvestService();

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date));
	};
	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const generalParams = {
			page: 1,
			limit: 1000,
		};
		const cropSeasonParams = {
			...generalParams,
			filterValue: encodeURI(JSON.stringify({finalizada: false}))
		};
		const cropSeasonsInProperty = await cropSeasonService.get(propertyId, cropSeasonParams);
		setAllSafras(await getSafrasWithDetails(cropSeasonsInProperty));

		const harvestId = searchParams.get("id");

		if (harvestId !== null) {
			setId(harvestId);
			const lote = await harvestService.getById(harvestId);
			const loteSafra = lote.loteSafras[0];
			setOldCropSeasonId(loteSafra.safraId);
			cropSeasonsInProperty.findIndex((cropSeason, index) => {
				if (cropSeason.id === loteSafra.safraId) {
					const farm = cropSeason.propriedadeCultivo;
					const subDiv = cropSeason.subdivisao;
					const cultivation = farm.cultivo;
					const catSub = subDiv?.categoriaSubdivisao;
					const plot = cropSeason.talhao;
					setSelectedSafra({
						title: `Talhão ${plot.nome}${
							subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
						} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
						id: cropSeason.id,
						index: index,
					});
				}
			});
			setSelectedDate(new Date(lote.dataPrevista));
			setQuantidadeTotal(lote.quantidade);
		}
	};

	const getSafrasWithDetails = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			if (cropSeason.finalizada === false) {
				const farm = cropSeason.propriedadeCultivo;
				const subDiv = cropSeason.subdivisao;
				const cultivation = farm.cultivo;
				const catSub = subDiv?.categoriaSubdivisao;
				const plot = cropSeason.talhao;
				safras.push({
					id: cropSeason.id,
					safra: cropSeason,
					nome: `Talhão ${plot.nome}${
						subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
					} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
				});
			}
		}
		return safras;
	};
	const validationSave = () => {
		if (selectedDate && selectedSafra !== null) {
			handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageSelectSafras(false);
			setErrorMessageDateCad(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (!selectedDate) {
				message += "Data de colheita, ";
				setErrorMessageDateCad(true);
			} else {
				setErrorMessageDateCad(false);
			}
			if (selectedSafra === null) {
				message += "Selecionar Safra..., ";
				setErrorMessageSelectSafras(true);
			} else {
				setErrorMessageSelectSafras(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};
	const handleSave = async () => {
		const safra = allSafras[selectedSafra.index];
		try {
			if (!id) {
				const harvestToSave = {
					totalAmount: Number(quantidadeTotal),
					farmId: safra.safra.propriedadeCultivoId,
					forecast: true,
					forecastDate: selectedDate,
					cropSeasonId:  safra.id,
				};
				await harvestService.save(harvestToSave);
			} else {
				const harvestToUpdate = {
					id,
					totalAmount: Number(quantidadeTotal),
					farmId: safra.safra.propriedadeCultivoId,
					forecast: true,
					forecastDate: selectedDate,
					newCropSeasonId:  safra.id,
					oldCropSeasonId
				};
				await harvestService.update(harvestToUpdate);
			}
		} catch (err) {
			console.log(err);
		}
	};
	const dataAllSafras = allSafras.map((item, index) => {
		return {
			title: item.nome,
			id: item.id,
			index: index,
		};
	});
	const optionsAllSafras = dataAllSafras.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	async function getUnidMedida(e) {
		let unidade = "";
		const safra = await cropSeasonService.getById(e.id);
		const embalagens = safra.propriedadeCultivo.embalagens;
		unidade = embalagens[0].unidadeMedida;
		/* setEmbalagemId(embalagens[0].id); */
		setUnid(unidade.sigla);
	}
	return (
		<div>
			<TopMenu open={["launch", "harvest"]} help="HarvestForecast" />
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						sx={{ display: "flex", width: "100%" }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title="Previssão de Colheita"
									href="/colheita"
									dataCriacao={true}
									dataAlteracao={true}
									salveFunc={validationSave}
									typeMessage={typeMessage}
									message={message}
								>
									<Grid
										container
										rowSpacing={3}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										sx={{ width: "100%" }}
									>
										<Grid item xs={12} sm={12} md={6}>
											<Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "column",
												alignItems: "flex-start",
												px: 1,
												pr:3,
												position: "relative",
												m:0
											}}>
												<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedSafra === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
													Safra
												</Typography>
												<div
													className={
														errorMessageSelectSafras ? styles.errControl : styles.noErrControl
													}
												>
													<p
														className={
															errorMessageSelectSafras ? styles.errText : styles.noErrText
														}
													>
														*
													</p>
													<ThemeProvider theme={theme}>
														<Autocomplete
															options={optionsAllSafras.sort(
																(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
															)}
															groupBy={(option) => option.firstLetter}
															getOptionLabel={(option) => option.title}
															sx={{ width: "100%", border: "none" }}
															value={selectedSafra === undefined ? "" : selectedSafra}
															onChange={(event, newValue) => {
																getUnidMedida(newValue);
																setSelectedSafra(newValue);
															}}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input
																		type="text"
																		{...params.inputProps}
																		placeholder="Selecionar Safra"
																		className={styles.inputHarvestForecast}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
												</div>
											</Box>
										</Grid>

										<Grid item xs={12} sm={12} md={6}>
											<Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "column",
												alignItems: "flex-start",
												px: 1,
												pr:3,
												position: "relative",
												m:0
											}}>
												<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedDate ?"visible" : "hidden"),transition:"all 0.5s ease"}} >
													Data
												</Typography>
												<div
													className={
														errorMessageDateCad ? styles.errControl : styles.noErrControl
													}
												>
													<p
														className={
															errorMessageDateCad ? styles.errText : styles.noErrText
														}
													>
														*
													</p>
													<InputData placeholder="Data" value={selectedDate ? selectedDate.toLocaleDateString("pt-BR") : ""} onDateChange={handleDateChange} classN={styles.inputHarvestForecast}/>
												</div>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={9}>
											<Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "column",
												alignItems: "flex-start",
												px: 1,
												pr:3,
												position: "relative",
												m:0
											}}>
												<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(quantidadeTotal === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
													Quantidade
												</Typography>
												<input
													type="number"
													placeholder="Quantidade"
													value={quantidadeTotal}
													onChange={(e) => setQuantidadeTotal(e.target.value)}
													className={styles.inputHarvestForecast}
												/>
											</Box>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											md={3}
											sx={{
												display: "flex",
												justifyContent: "flex-start",
												alignContent: "flex-start",
												marginLeft: "20px",
												marginRight: "-20px",
												marginTop: "20px",
											}}
										>
											<Item sx={stylesItem}>{unid}</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default HarvestForecast;
