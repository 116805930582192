import React, { useState } from "react";
import styles from "./LoginRequest.module.css";
import logo from "../../../../Assets/images/logo.svg";

// icons import
import HttpsIcon from "@mui/icons-material/Https";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
// import { Auth } from "aws-amplify";
import ShieldIcon from "@mui/icons-material/Shield";
import { useParams } from "react-router-dom";
import { AuthService } from "../../../../service/auth_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function LoginRequestNewPassword() {
	const [showPassword, setShowPassword] = useState(true);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [code, setCode] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const { id } = useParams();

	const authService = new AuthService();

	function handleClick() {
		setShowPassword(!showPassword);
		if (showPassword) document.getElementById("password").type = "text";
		else document.getElementById("password").type = "password";
	}
	
	function handleClickConfirm() {
		setShowPasswordConfirm(!showPasswordConfirm);
		if (showPasswordConfirm)
			document.getElementById("passwordConfirm").type = "text";
		else document.getElementById("passwordConfirm").type = "password";
	}

	async function changePassword(e) {
		e.preventDefault();
		if (password === passwordConfirm) {
			try {
				const body = {
					userId: id,
					token: code,
					password
				};
				await authService.resetPasswordWithToken(body);
				setSuccessMsg("Senha alterada com sucesso!");
				await new Promise(r => setTimeout(r, 3000));
				window.location.href = "/";
			} catch(err) {
				setErrorMsg(err.message);
			}
		} else {
			setErrorMsg("Senhas Diferentes");
		}
	}
	//hltm login
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "ceter",
					alignItems: "center",
					bgcolor: "white",
					p: 0,
					m: 0,
				}}
			>
				<Box
					sx={{
						p: 0,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "ceter",
							alignItems: "center",
							flexDirection: {
								xs: "column-reverse",
								sm: "column-reverse",
								md: "row",
							},
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "ceter",
								alignItems: "center",
							}}
						>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100%",
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									sx={{ px: { xs: 3, sm: 3, md: 2 } }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											sx={{
												with: "100%",
												height: "100% ",
												border: "none",
												boxShadow: "none",
											}}
										>
											<img className={styles.logo} src={logo} />
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											className={styles.loginTextContainer}
											sx={{ width: "100%", border: "none", boxShadow: "none" }}
										>
											<h2>Tecnologia com soluções para o agronegocio</h2>
											<p>
												Facilitamos a adequação ás legislações referentes a
												rastreabiidade vegetal, garantindo a segurança dos
												alimentos.
											</p>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
						{/* form  */}
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								background:
									"linear-gradient(180deg, #1A877F 0%, #12B6AA 100%)",
							}}
						>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100vh",
									p: { xs: 3, sm: 15, md: 15 },
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											m: 0,
											height: "100%",
										}}
									>
										<Item sx={{ border: "none", boxShadow: "none" }}>
											<h2 className={styles.title}>Redefinição de senha</h2>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemError}>{errorMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemSuccess}>{successMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<ShieldIcon className={styles.icon} />
														<input
															id="codigo"
															type="text"
															className={styles.input}
															placeholder="Codigo de verificação"
															value={code}
															onChange={(e) => setCode(e.target.value)}
														/>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<HttpsIcon className={styles.icon} />
														<input
															id="password"
															type="password"
															className={styles.input}
															placeholder="Nova senha"
															value={password}
															onChange={(e) => setPassword(e.target.value)}
														/>
														<div onClick={handleClick}>
															{showPassword ? (
																<VisibilityIcon
																	className={styles.iconPassword}
																/>
															) : (
																<VisibilityOffIcon
																	className={styles.iconPassword}
																/>
															)}
														</div>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<HttpsIcon className={styles.icon} />
														<input
															id="passwordConfirm"
															type="password"
															className={styles.input}
															placeholder="Confirmar senha"
															value={passwordConfirm}
															onChange={(e) =>
																setPasswordConfirm(e.target.value)
															}
														/>
														<div onClick={handleClickConfirm}>
															{showPasswordConfirm ? (
																<VisibilityIcon
																	className={styles.iconPassword}
																/>
															) : (
																<VisibilityOffIcon
																	className={styles.iconPassword}
																/>
															)}
														</div>
													</Item>
												</Grid>

												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<button
															className={styles.buttonLogin}
															type="submit"
															onClick={changePassword}
														>
															Salvar
														</button>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p>Ja possui uma conta? </p>
														<a className={styles.link} href="/">
															Voltar para tela de login
														</a>
													</Item>
												</Grid>
											</Grid>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default LoginRequestNewPassword;
