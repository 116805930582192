import { FarmRepository } from "../repository/farm_repository";

export class FarmService {

	constructor() {
		this.farmRepository = new FarmRepository();
	}

	async save(farm) {
		const savedFarm = await this.farmRepository.save(farm);
		return savedFarm;
	}

	async update(farm) {
		const savedFarm = await this.farmRepository.update(farm);
		return savedFarm;
	}

	async get(params, propertyId) {
		const farmData = await this.farmRepository.get(params, propertyId);
		return farmData.farms;
	}

	async getById(id) {
		return await this.farmRepository.getById(id);
	}

	async delete(id) {
		await this.farmRepository.delete(id);
	}

	async getPackages(params, farmId) {
		return await this.farmRepository.getPackages(params, farmId);
	}

	async getPackageById(id) {
		return await this.farmRepository.getPackageById(id);
	}

	async savePackage(farmPackage) {
		return await this.farmRepository.savePackage(farmPackage);
	}

	async updatePackage(farmPackage) {
		return await this.farmRepository.updatePackage(farmPackage);
	}

	async deletePackage(id) {
		await this.farmRepository.deletePackage(id);
	}
}