import React, { useState } from "react";
import styles from "./LoginOption.module.css";
import logo from "../../../../Assets/images/logo.svg";
// import InputMask from "../../../components/InputMask";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { AuthService } from "../../../../service/auth_service";
// import { Auth } from "aws-amplify";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function LoginOptionsNewPass() {
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [username, setUsername] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const authService = new AuthService();

	async function confirm(e) {
		e.preventDefault();
		try {
			setIsLoading(true);
			const userId = await authService.sendResetPasswordToken(username);
			setSuccessMsg("Código enviado para o e-mail cadastrado");
			await new Promise(r => setTimeout(r, 3000));
			window.location.href = `/Login/nova-senha/${userId}`;
			setIsLoading(false);
			setErrorMsg("");
		} catch (err) {
			setErrorMsg(err.message);
		}
	}

	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "ceter",
					alignItems: "center",
					bgcolor: "white",
					p: 0,
					m: 0,
				}}
			>
				<Box
					sx={{
						p: 0,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "ceter",
							alignItems: "center",
							flexDirection: {
								xs: "column-reverse",
								sm: "column-reverse",
								md: "row",
							},
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "ceter",
								alignItems: "center",
							}}
						>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100%",
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									sx={{ p: { xs: 3, sm: 3, md: 2 } }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											sx={{
												with: "100%",
												height: "100% ",
												border: "none",
												boxShadow: "none",
											}}
										>
											<img className={styles.logo} src={logo} />
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											className={styles.loginTextContainer}
											sx={{ width: "100%", border: "none", boxShadow: "none" }}
										>
											<h2>Tecnologia com soluções para o agronegocio</h2>
											<p>
												Facilitamos a adequação ás legislações referentes a
												rastreabiidade vegetal, garantindo a segurança dos
												alimentos.
											</p>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
						{/* form  */}
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								background: "linear-gradient(180deg, #1A877F 0%, #12B6AA 100%)",
							}}
						>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100vh",
									p: { xs: 3, sm: 15, md: 15 },
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											m: 0,
											height: "100%",
										}}
									>
										<Item sx={{ border: "none", boxShadow: "none" }}>
											<h2>Redefinir Senha</h2>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemError}>{errorMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemSuccess}>{successMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														{/* {contactType === "email" ? ( */}
														<input
															type="text"
															id="username"
															value={username}
															onChange={(e) => setUsername(e.currentTarget.value)}
															placeholder="Digite seu nome de usuário"
															className={styles.input}
														/>
													</Item>
												</Grid>

												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														{!isLoading ? (
															<button
																className={styles.buttonLogin}
																type="submit"
																onClick={confirm}
															>
																Confirmar
															</button>
														) : (
															<button className={styles.buttonLoginCarregando}>
																<div className={styles.ldsroller}>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																</div>
															</button>
														)}
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p>Não possui uma conta?</p>
														<a className={styles.link} href="https://wa.me/5585981310242?text=Ol%C3%A1%2C%20estou%20no%20site%20sisagri%20e%20gostaria%20de%20solicitar%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20solu%C3%A7%C3%A3o">
															Solicitar cadastro
														</a>
													</Item>
												</Grid>
											</Grid>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default LoginOptionsNewPass;
