import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function HelpButton(props) {
	return (
		<PopupState variant="popover">
			{(popupState) => (
				<div>
					<Button
						sx={{
							borderRadius: "100%",
							width: "50px",
							height: "50px",
							p: 0,
							ml: 5,
							m: 0,
							bgcolor: "white",
							color: "black",
						}}
						{...bindTrigger(popupState)}
					>
						<InfoOutlinedIcon sx={{ fontSize: "20px" }} />
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<Typography
							sx={{
								p: 3,
								pb: 0,
								width: { sx: "90vw", sm: "50vw", md: "35vw" },
							}}
						>
							<b>{props.title}</b>
						</Typography>
						<Typography
							sx={{ p: 3, width: { sx: "90vw", sm: "50vw", md: "35vw" } }}
						>
							{props.text}
							<a href={props.link}>{props.link}</a>
						</Typography>
					</Popover>
				</div>
			)}
		</PopupState>
	);
}
