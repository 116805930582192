import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Grid, Typography } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import InputMask from "../../../../components/InputMask";
import styles from "../../SettingsPag.module.css";
import ModalC from "./Modal";
import { SettingsService } from "../../../../service/settings_service";

function PlataformaRastreabilidade() {
	const [socialNetworkId, setSocialNetworkId] = useState(null);
	const [traceabilityPortalDataId, setTraceabilityPortalDataId] = useState(null);
	const [phone, setPhone] = useState("");
	const [facebook, setFacebook] = useState("");
	const [instagram, setInstagram] = useState("");
	const [linkedin, setLinkedin] = useState("");
	const [site, setSite] = useState("");
	const [titulo, setTitulo] = useState("");
	const [subTitulo, setSubTitulo] = useState("");
	const [descricao, setDescricao] = useState("");
	const [openfacebook, setOpenFacebook] = useState(false);
	const [openinstagram, setOpeninstagram] = useState(false);
	const [openlinkedin, setOpenLinkedin] = useState(false);
	const [openPhone, setOpenPhone] = useState(false);
	const [openSubTitulo, setOpenSubTitulo] = useState(false);
	const [openTitulo, setOpenTitulo] = useState(false);
	const [openDescricao, setOpenDescricao] = useState(false);
	const [opensite, setOpenSite] = useState(false);
	const [openGaleria, setOpenGaleria] = useState(false);
	const [openFotoProdutor, setOpenFotoProdutor] = useState(false);
	const [files, setFiles] = useState([]);
	const [filesProdutor, setFilesProdutor] = useState([]);
	const [initialFiles, setInitialFiles] = useState([]);
	const [initialFilesProdutor, setInitialFilesProdutor] = useState([]);
	// const [produtor, setProdutor] = useState([]);

	const settingsService = new SettingsService();

	useEffect(() => {
		initData();
		downloadFiles();
		downloadFilesProdutor();
	}, []);
	const [aux, setAux] = useState("");

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const traceabilityData = await settingsService.getTraceabilityPortalDataByProperty(propertyId);
		const socialNetwork = await settingsService.getSocialNetwork(propertyId);
		if (socialNetwork) {
			setSocialNetworkId(socialNetwork.id);
			if (
				socialNetwork.whatsapp !== null &&
				socialNetwork.whatsapp !== undefined
			) {
				const phoneParts = socialNetwork.whatsapp.split("phone=");
				if (
					phoneParts.length > 1 &&
					phoneParts[1] !== null &&
					phoneParts[1] !== undefined
				) {
					const phone = phoneParts[1].replace("+55", "").replace(" ", "");
					setPhone(phone);
				}
			}
			const facebook = socialNetwork.facebook;
			const site = socialNetwork.site;
			const linkedin = socialNetwork.linkedin;
			const instagram = socialNetwork.instagram;
			if (facebook !== null && facebook !== undefined) setFacebook(facebook);
			if (site !== null && site !== undefined) setSite(site);
			if (linkedin !== null && linkedin !== undefined) setLinkedin(linkedin);
			if (instagram !== null && instagram !== undefined)
				setInstagram(instagram);
		}

		if (traceabilityData) {
			const title = traceabilityData.tituloImagem ?? "";
			const subtitle = traceabilityData.subtituloImagem ?? "";
			const description = traceabilityData.descricaoPropriedade ?? "";

			setTraceabilityPortalDataId(traceabilityData.id);
			setTitulo(title);
			setSubTitulo(subtitle);
			setDescricao(description);
		}
	};

	const downloadFiles = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const initiFiles = [];
		const filesInStorage = await settingsService.getPropertyGalleryImages(propertyId);
		for (let i = 0; i < filesInStorage.length; i++) {
			const fileInStorage = filesInStorage[i];
			const blob = await fetch(fileInStorage).then(r => r.blob());
			initiFiles.push(
				new File([blob], `${i}.jpg`, {
					type: "image/jpeg",
				})
			);
		}
		setInitialFiles(initiFiles);
	};

	const downloadFilesProdutor = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const initiFiles = [];

		const base64 = await settingsService.getTraceabilityImage(propertyId);

		if(base64 && base64 !== ""){
			const mimeType = getMimeType(base64);
			const binaryString = atob(base64, "base64");

			let bytes = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++)
				bytes[i] = binaryString.charCodeAt(i);

			const blob = new Blob([bytes], { type: mimeType });

			initiFiles.push(
				new File([blob], "0", {
					type: mimeType,
				})
			);

			setInitialFilesProdutor(initiFiles);
		}
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	const handleOpenFotoProdutor = () => {
		setAux(facebook);
		setOpenFotoProdutor(true); //abre o modal
	};
	const handleOpenGaleria = () => {
		setAux(facebook);
		setOpenGaleria(true); //abre o modal
	};
	const handleOpenSite = () => {
		setAux(facebook);
		setOpenSite(true); //abre o modal
	};
	const handleOpenFacebbok = () => {
		setAux(facebook);
		setOpenFacebook(true); //abre o modal
	};
	const handleOpenPhone = () => {
		setAux(phone);
		setOpenPhone(true); //abre o modal
	};
	const handleOpenIntagram = () => {
		setAux(phone);
		setOpeninstagram(true); //abre o modal
	};
	const handleOpenLinkedin = () => {
		setAux(phone);
		setOpenLinkedin(true); //abre o modal
	};
	const handleOpenTitulo = () => {
		setAux(titulo);
		setOpenTitulo(true); //abre o modal
	};
	const handleOpenSubTitulo = () => {
		setAux(subTitulo);
		setOpenSubTitulo(true); //abre o modal
	};
	const handleOpenDescricao = () => {
		setAux(descricao);
		setOpenDescricao(true); //abre o modal
	};
	const handleClose = () => {
		setOpenSite(false); //fecha o modal
		setOpeninstagram(false); //fecha o modal
		setOpenPhone(false); //fecha o modal
		setOpenFacebook(false); //fecha o modal
		setOpenTitulo(false);
		setOpenSubTitulo(false);
		setOpenDescricao(false);
		setOpenFotoProdutor(false);
		setOpenGaleria(false);
		setOpenLinkedin(false);
	};
	const handleCloseCancelFotoProdutor = () => {
		setOpenFotoProdutor(false); //fecha o modal
	};
	const handleCloseCancelGaleria = () => {
		setOpenGaleria(false); //fecha o modal
	};
	const handleCloseCancelSite = () => {
		setSite(aux);
		setOpenSite(false); //fecha o modal
	};
	const handleCloseCancelFacebook = () => {
		setFacebook(aux);
		setOpenFacebook(false); //fecha o modal
	};
	const handleCloseCancelinstagram = () => {
		setInstagram(aux);
		setOpeninstagram(false); //fecha o modal
	};
	const handleCloseCancelPhone = () => {
		setPhone(aux);
		setOpenPhone(false); //fecha o modal
	};
	const handleCloseCancelLinkedin = () => {
		setPhone(aux);
		setOpenLinkedin(false); //fecha o modal
	};
	const handleCloseCancelTitulo = () => {
		setTitulo(aux);
		setOpenTitulo(false); //fecha o modal
	};
	const handleCloseCancelSubTitulo = () => {
		setSubTitulo(aux);
		setOpenSubTitulo(false); //fecha o modal
	};
	const handleCloseCancelDescricao = () => {
		setDescricao(aux);
		setOpenDescricao(false); //fecha o modal
	};

	const handleCloseGaleria = async () => {
		await handleSaveGaleria();
		handleClose();
	};

	const handleCloseImagemProdutor = async () => {
		await handleSaveImagemProdutor();
		handleClose();
	};

	const handleCloseWebsite = async () => {
		await handleSaveSocialNetwork();
		handleClose();
	};

	const handleCloseFacebook = async () => {
		await handleSaveSocialNetwork();
		handleClose();
	};

	const handleCloseLinkedin = async () => {
		await handleSaveSocialNetwork();
		handleClose();
	};

	const handleCloseInstagram = async () => {
		await handleSaveSocialNetwork();
		handleClose();
	};

	const handleClosePhone = async () => {
		await handleSaveSocialNetwork();
		handleClose();
	};

	const handleCloseTitulo = async () => {
		await handleSaveTraceabilityPortalDataByProperty();
		handleClose();
	};

	const handleCloseSubTitulo = async () => {
		await handleSaveTraceabilityPortalDataByProperty();
		handleClose();
	};

	const handleCloseDescricao = async () => {
		await handleSaveTraceabilityPortalDataByProperty();
		handleClose();
	};

	const handleSaveSocialNetwork = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const phoneWithoutFormat = phone
			.replace("+55", "")
			.replace("(", "")
			.replace(")", "")
			.replace("-", "");
		
		const whatsapp = phoneWithoutFormat !== "" ?
			`https://web.whatsapp.com/send?phone=${phoneWithoutFormat}`
			: null;
		
		const socialNetwork = {
			whatsapp: whatsapp,
			linkedin: linkedin !== "" ? linkedin : null,
			site: site !== "" ? site : null,
			facebook: facebook !== "" ? facebook : null,
			instagram: instagram !== "" ? instagram : null,
		};

		if(socialNetworkId) {
			socialNetwork.id = socialNetworkId;
			await settingsService.updateSocialNetwork(socialNetwork);
		} else {
			socialNetwork.propertyId = propertyId;
			await settingsService.saveSocialNetwork(socialNetwork);
		}
		initData();
	};

	const handleSaveTraceabilityPortalDataByProperty = async () => {
		const propertyId = localStorage.getItem("propertyId");
		
		const traceabilityPortalData = {
			imageTitle: titulo !== "" ? titulo : null,
			imageSubtitle: subTitulo !== "" ? subTitulo : null,
			description: descricao !== "" ? descricao : null,
		};

		if(traceabilityPortalDataId) {
			traceabilityPortalData.id = traceabilityPortalDataId;
			await settingsService.updateTraceabilityPortalData(traceabilityPortalData);
		} else {
			traceabilityPortalData.propertyId = propertyId;
			await settingsService.saveTraceabilityPortalData(traceabilityPortalData);
		}
		initData();
	};

	const handleSaveGaleria = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const galleryImagesToSave = new FormData();

		for (let i = 0; i < files.length; i++) {
			const image = files[i];
			galleryImagesToSave.append("images", image);
		}

		if(initialFiles.length > 0)
			await settingsService.updatePropertyGalleryImages(propertyId, galleryImagesToSave);
		else
			await settingsService.savePropertyGalleryImages(propertyId, galleryImagesToSave);

		await downloadFiles();
		setFiles([]);
	};

	const handleSaveImagemProdutor = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const traceabilityImagesToSave = new FormData();

		for (let i = 0; i < filesProdutor.length; i++) {
			const image = filesProdutor[i];
			traceabilityImagesToSave.append("image", image);
		}

		if(initialFilesProdutor.length > 0)
			await settingsService.updateTraceabilityImage(propertyId, traceabilityImagesToSave);
		else 
			await settingsService.saveTraceabilityImage(propertyId, traceabilityImagesToSave);

		await downloadFilesProdutor();
		setFilesProdutor([]);
	};

	function formatPhone(phone) {
		let test = phone.split("");
		return (
			"(" +
			(test[0] ?? "") +
			(test[1] ?? "") +
			") " +
			(test[2] ?? "") +
			(test[3] ?? "") +
			(test[4] ?? "") +
			(test[5] ?? "") +
			(test[6] ?? "") +
			" - " +
			(test[7] ?? "") +
			(test[8] ?? "") +
			(test[9] ?? "") +
			(test[10] ?? "")
		);
	}

	return (
		<>
			<Box component="main">
				<Box
					sx={{
						width: "100%",
						minWidth: { xs: "100vw", sm: "66.666667vw", md: "75vw" },
						height: "100%",
						minHeight: "90vh",
						bgcolor: "white",
						m: 0,
						mt: 2,
						borderRadius: 8,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={0}
						sx={{
							display: "flex",
							width: "100%",
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							p: 3,
							overflowY: "auto",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "10px",
									paddingBottom: "10px",
									borderBottom: "1px solid",
								}}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Typography
										variant="h4"
										sx={{
											fontWeight: "600",
											fontSize: {
												xs: "18px",
												sm: "18px",
												md: "12px",
												lg: "18px",
											},
										}}
									>
										Configurações das Redes sociais
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Web Site</p>
									<h4>{site.length === 0 ? "Nenhum site cadastrado" : site}</h4>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<button
										onClick={handleOpenSite}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Whatsapp</p>
									<h4>
										{phone.length === 0
											? "Nenhum telefone cadastrado"
											: formatPhone(phone)}
									</h4>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<button
										onClick={handleOpenPhone}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>linkedin</p>
									<h4>
										{linkedin.length === 0
											? "Nenhum linkedin cadastrado"
											: linkedin}
									</h4>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<button
										onClick={handleOpenLinkedin}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Facebook</p>
									<h4>
										{facebook.length === 0
											? "Nenhum facebook cadastrado"
											: facebook}
									</h4>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<button
										onClick={handleOpenFacebbok}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Instagram</p>
									<h4>
										{instagram.length === 0
											? "Nenhum instagram cadastrado"
											: instagram}
									</h4>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<button
										onClick={handleOpenIntagram}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "10px",
									paddingBottom: "10px",
									borderBottom: "1px solid",
								}}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Typography
										variant="h4"
										sx={{
											fontWeight: "600",
											fontSize: {
												xs: "18px",
												sm: "18px",
												md: "12px",
												lg: "18px",
											},
										}}
									>
										Configuranções da Descrição de Produtor / Propriedade
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Titulo</p>
									<h4>
										{titulo.length === 0 ? "Nenhum título cadastrado" : titulo}
									</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenTitulo}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Sub-Titulo</p>
									<h4>
										{subTitulo.length === 0
											? "Nenhum subtítulo cadastrado"
											: subTitulo}
									</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenSubTitulo}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Descrição da Propriedade</p>

									{/* <h4 >
								{descricao}
							</h4> */}
									<textarea
										style={{
											width: "90%",
											minWidth: "90%",
											maxWidth: "90%",
											padding: "15px",
											borderRadius: "8px",
											height: "max-content",
											minHeight: "100px",
											maxHeight: "200px",
										}}
										disabled
										value={
											descricao.length === 0
												? "Nenhuma descrição cadastrada"
												: descricao
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenDescricao}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "10px",
									// paddingBottom: "10px",
									// borderBottom:"1px solid"
								}}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Typography
										variant="h4"
										sx={{
											fontWeight: "600",
											fontSize: {
												xs: "18px",
												sm: "18px",
												md: "12px",
												lg: "18px",
											},
										}}
									>
										Configuranção das Imagens
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										height: "100%",
										textAlign: "start",
										flexDirection: "column",
										justifyContent: "center",
										my: 5,
										// bgcolor:"red"
									}}
								>
									{initialFilesProdutor.length > 0 && (
										<img
											src={URL.createObjectURL(initialFilesProdutor[0])}
											style={{ width: "50%", height: "50%" }}
										/>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										height: "400px",
										textAlign: "center",
										justifyContent: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenFotoProdutor}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={1}
										sx={{
											display: "flex",
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{initialFiles.map((file, index) => {
											return (
												<Grid key={index} item xs={"auto"}>
													<img
														src={URL.createObjectURL(file)}
														style={{ width: "auto", height: "100px" }}
													/>
												</Grid>
											);
										})}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenGaleria}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<ModalC
				open={openPhone}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelPhone}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleClosePhone}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>WhatsApp</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<InputMask
						value={phone}
						placeholder="Telefone"
						mask="(99)99999-9999"
						onChange={(e) => setPhone(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openfacebook}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelFacebook}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseFacebook}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Facebook</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={facebook}
						onChange={(e) => setFacebook(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openinstagram}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelinstagram}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseInstagram}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Instagram</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={instagram}
						onChange={(e) => setInstagram(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openlinkedin}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelLinkedin}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseLinkedin}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Linkedin</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={linkedin}
						onChange={(e) => setLinkedin(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={opensite}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelSite}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseWebsite}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Web Site</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={site}
						onChange={(e) => setSite(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openTitulo}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelTitulo}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseTitulo}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Titulo</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={titulo}
						onChange={(e) => setTitulo(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openSubTitulo}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelSubTitulo}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseSubTitulo}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Sub-Titulo</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<input
						type="text"
						value={subTitulo}
						onChange={(e) => setSubTitulo(e.target.value)}
					/>
				</Grid>
			</ModalC>
			<ModalC
				open={openDescricao}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelDescricao}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseDescricao}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Descrição da propriedade</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<textarea
						id="w3review"
						name="w3review"
						rows="4"
						cols="50"
						value={descricao}
						onChange={(e) => setDescricao(e.target.value)}
						style={{
							width: "100%",
							minWidth: "100%",
							maxWidth: "100%",
							padding: "15px",
							borderRadius: "8px",
							height: "max-content",
							minHeight: "200px",
							maxHeight: "400px",
						}}
					>
						{descricao}
					</textarea>
				</Grid>
			</ModalC>
			<ModalC
				open={openGaleria}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelGaleria}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseGaleria}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Imagem para Galeria da Propriedade</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					{initialFiles.length === 0 && (
						<DropzoneArea
							Icon={CameraAltIcon}
							onChange={(addedFiles) => {
								if (addedFiles.length <= 10) setFiles(addedFiles);
							}}
							acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
							showPreviews={true}
							showPreviewsInDropzone={false}
							filesLimit={10}
							maxFileSize={5000000}
							previewText=" "
							dropzoneText="Faça upload de até dez imagens para a galeria"
							getFileLimitExceedMessage={(filesLimit) =>
								`Quantidade excedida: ${filesLimit} `
							}
							getFileAddedMessage={(fileName) =>
								`Imagen adicionada: ${fileName} `
							}
							getFileRemovedMessage={(fileName) =>
								`Imagen removida: ${fileName} `
							}
							showAlerts={["success", "info"]}
							// initialFiles={initialFiles}
						/>
					)}
					{initialFiles.length > 0 && (
						<DropzoneArea
							Icon={CameraAltIcon}
							onChange={(addedFiles) => {
								if (addedFiles.length <= 10) setFiles(addedFiles);
							}}
							acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
							showPreviews={true}
							showPreviewsInDropzone={false}
							filesLimit={10}
							maxFileSize={5000000}
							previewText=" "
							dropzoneText="Faça upload de até dez imagens para a galeria"
							getFileLimitExceedMessage={(filesLimit) =>
								`Quantidade excedida: ${filesLimit} `
							}
							getFileAddedMessage={(fileName) =>
								`Imagem adicionada: ${fileName} `
							}
							getFileRemovedMessage={(fileName) =>
								`Imagem removida: ${fileName} `
							}
							showAlerts={["success", "info"]}
							initialFiles={initialFiles}
						/>
					)}
				</Grid>
			</ModalC>
			<ModalC
				open={openFotoProdutor}
				onClose={handleClose}
				classN={styles.registrationInputModal}
				gridClassName={styles.registrationInputModal}
				onClickButtonCancel={handleCloseCancelFotoProdutor}
				buttonCancelClassName={styles.registrationButtonCancelar}
				onClickButtonCadastrar={handleCloseImagemProdutor}
				buttonCadastraClassName={styles.registrationButtoncadastrar}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p>Foto do Proprietario/propriedade</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					{initialFilesProdutor.length === 0 && (
						<DropzoneArea
							Icon={CameraAltIcon}
							onChange={(addedFiles) => {
								if (addedFiles.length <= 10) setFilesProdutor(addedFiles);
							}}
							acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
							showPreviews={true}
							showPreviewsInDropzone={false}
							filesLimit={1}
							maxFileSize={5000000}
							previewText=" "
							dropzoneText="Faça upload de uma imagem da propriedade/produtor"
							getFileLimitExceedMessage={(filesLimit) =>
								`Quantidade excedida: ${filesLimit} `
							}
							getFileAddedMessage={(fileName) =>
								`Imagen adicionada: ${fileName} `
							}
							getFileRemovedMessage={(fileName) =>
								`Imagen removida: ${fileName} `
							}
							showAlerts={["success", "info"]}
							// initialFiles={initialFiles}
						/>
					)}
					{initialFilesProdutor.length > 0 && (
						<DropzoneArea
							Icon={CameraAltIcon}
							onChange={(addedFiles) => {
								if (addedFiles.length <= 1) setFilesProdutor(addedFiles);
							}}
							acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
							showPreviews={true}
							showPreviewsInDropzone={false}
							filesLimit={1}
							maxFileSize={5000000}
							previewText=" "
							dropzoneText="Faça upload de uma imagem da propriedade/produtor"
							getFileLimitExceedMessage={(filesLimit) =>
								`Quantidade excedida: ${filesLimit} `
							}
							getFileAddedMessage={(fileName) =>
								`Imagem adicionada: ${fileName} `
							}
							getFileRemovedMessage={(fileName) =>
								`Imagem removida: ${fileName} `
							}
							showAlerts={["success", "info"]}
							initialFiles={initialFilesProdutor}
						/>
					)}
				</Grid>
			</ModalC>
		</>
	);
}

export default PlataformaRastreabilidade;
