import axios from "axios";
export class AuthRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 300000,
		});
	}

	async authenticate(username, password) {
		console.log("AUTENTICANDO");
		console.log(process.env.REACT_APP_BASE_URL);
		try {
			const result = await this.axios.post("auth/login", 
			
				{
					"username": username,
					"password": password
				}
			);

			return result.data.access_token;
		} catch (error) {
			if(error.response.status === 404) 
				throw new Error("E-mail ou senha incorreto.");

			throw new Error("Erro interno.");
		}
	}

	async getProfile() {
		try {
			const result = await this.axios.get("auth/profile", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			if(error.response.status === 401) 
				throw new Error("Usuário não autenticado.");

			throw new Error("Erro interno.");
		}
	}

	async sendResetPasswordToken(username) {
		try {
			const result = await this.axios.post("auth/reset-password", 
				{
					"username": username
				}
			);

			return result.data.userId;
		} catch (error) {
			if(error.response.status === 404) 
				throw new Error("Usuário não encontrado.");

			throw new Error("Erro interno.");
		}
	}

	async resetPasswordWithToken(body) {
		try {
			const result = await this.axios.post("auth/new-password", 
				body
			);

			return result.data.userId;
		} catch (error) {
			if(error.response.status === 404) 
				throw new Error("Token inválido ou usuário inexistente.");
			if(error.response.status === 406) 
				throw new Error("Token inválido ou expirado.");

			throw new Error("Erro interno.");
		}
	}
}