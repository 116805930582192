import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";

//import css styles
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { PropertyServiceService } from "../../service/property_service_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id:"nome",
		label: "Nome",
	},
	{
		id: "grupo",
		label: "Tipo",
	},
	{
		id: "valorHora",
		label: "Valor / hora",
	}
];

function Services() {
	const [search, setSearch] = useState("");
	const [services, setServices] = useState([]);

	const propertyServiceService = new PropertyServiceService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const serviceParams = {
			page: 1,
			limit: 1000,
		};
		let servicesInProperty = await propertyServiceService.get(propertyId, serviceParams);

		servicesInProperty = servicesInProperty.map((serviceInProperty) => {
			console.log(serviceInProperty);
			return {
				id: serviceInProperty.id,
				nome: serviceInProperty.servico.nome,
				grupo: serviceInProperty.servico.grupoServico.nome,
				valorHora: serviceInProperty.valorHora ?? 0,
			};
		});
		setServices(servicesInProperty);
	};

	const dataFilter = services.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await propertyServiceService.delete(id);
		initData();
	};

	return (
		<div>
			<TopMenu open={["register", "services"]} help="services" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Serviços"
									href="/servicos/cadastroServico"
									textButton="Novo Serviço"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									handleDelete={handleDelete}
									editHref="/servicos/cadastroServico?id="
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Services;
