/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import TopMenu from "../../components/TopMenu";
import Search from "../../components/SearchHeader";
import styles from "./Report.module.css";
import "../CommonStyle/style.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CadernoCampo from "../../components/ReportCharts/CadernoCampo";
import BalancoProducao from "../../components/ReportCharts/BalancoProducao";
import Produtividade from "../../components/ReportCharts/Produtividade";
import RelatorioVendas from "../../components/ReportCharts/RelatorioVendas";
import RelatorioAtividades from "../../components/ReportCharts/RelatorioAtividades";
// import RelatorioRastreabilidade from "../../components/ReportCharts/RelatorioRastreabilidade";
import RelatoriosEstoqueInsumos from "../../components/ReportCharts/RelatoriosEstoqueInsumos";
import RelatoriosOcorrencia from "../../components/ReportCharts/RelatoriosOcorrencia";
import RelatoriosPerdas from "../../components/ReportCharts/RelatoriosPerdas";
//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import RelatorioGeral from "../../components/ReportCharts/RelatorioGeral";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const data = [
	["Meses", "Aplicações", "Produções"],
	["", 25, 19],
	["Dez", 25, 24],
	["", 40, 23],
	["Jan", 20, 26],
	["", 30, 20],
	["Fev", 20, 15],
	["", 29, 29],
	["Mar", 10, 22],
	["", 52, 24],
	["Abr", 9, 13],
	["", 25, 30],
	["Mai", 31, 24],
	["", 10, 31],
	["Jun", 29, 25],
	["", 26, 50],
];
const options = {
	title: "Resumo",
	curveType: "function",
	legend: { position: "top" },
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ width: "auto", px: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const arrayreporttitle = [
	{ title: "Caderno de campo", componet: CadernoCampo },
	{ title: "Produtividade", componet: Produtividade },
	{ title: "Balanço de produção", componet: BalancoProducao },
	{ title: "Relatório de perdas", componet: RelatoriosPerdas },
	{ title: "Relatório de ocorrências", componet: RelatoriosOcorrencia },
	// { title: "Relatório de rastreabilidade", componet: RelatorioRastreabilidade },
	{
		title: "Relatório de estoque de insumos",
		componet: RelatoriosEstoqueInsumos,
	},
	{ title: "Relatório de atividades", componet: RelatorioAtividades },
	{ title: "Relatório de vendas", componet: RelatorioVendas },
	{ title: "Relatório geral", componet: RelatorioGeral },
];

function Report() {
	//url control
	const url = new URLSearchParams(window.location.search);
	const indexUrl = url.get("index");

	const valorinicial = indexUrl !== null ? +indexUrl : 0;
	const [value, setValue] = React.useState(valorinicial);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [title, setTitle] = useState("Caderno de campo");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setTitle(arrayreporttitle[value].title);
	}, [value]);

	return (
		<div>
			<TopMenu open={["Query", "report"]} help="report" />
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title={title}
									href="cadastroPerdas"
									button={
										<Button
											aria-controls={open ? "basic-menu" : undefined}
											aria-haspopup="true"
											aria-expanded={open ? "true" : undefined}
											onClick={handleClick}
											className={styles.ReportButton}
										>
											{title}
											{open ? <ExpandLess /> : <ExpandMore />}
										</Button>
									}
								></Search>
							</Item>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								alignItems: "stretch",
							}}
						>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									width: "100%",
									m: 0,
									display: "flex",
									alignItems: "stretch",
								}}
							>
								{arrayreporttitle.map((report, index) => {
									return (
										<TabPanel
											className="tabPanel"
											value={value}
											index={index}
											key={index}
										>
											{
												<report.componet
													data={data}
													options={options}
													index={index}
												/>
											}
										</TabPanel>
									);
								})}
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={handleClose}>
					<Tabs
						value={value}
						onChange={handleChange}
						textColor="white"
						indicatorColor="primary"
						orientation="vertical"
					>
						{arrayreporttitle.map((report, index) => (
							<Tab
								key={index}
								className={styles.menuItemReport}
								label={report.title}
								{...a11yProps(index)}
							/>
						))}
					</Tabs>
				</MenuItem>
			</Menu>
		</div>
	);
}

export default Report;
