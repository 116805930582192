import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
// import logoHorizontal from "../../../../Assets/images/logoMenu.png";
import styles from "../../LandingPage.module.css";

const balaoInfoStyle = {
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
	textAlign: "center",
	minHeight: "250px",
	height: "100%",
	p: 5,
	borderRadius: "30px",
	bgcolor:"rgba(250,250,250,0.9)",
	color: " rgba(100,100,100,0.75)",
	boxShadow: " -7px 10px 43px -15px rgba(0,0,0,0.75)",
};
const balaoInfoTopStyle = {
	bgcolor: "gray",
	width: "max-content",
	borderRadius: "15px",
	p: 2,
	ml: "20px",
	mb: -3,
	position: "relative",
	color: "white",
	boxShadow: "-7px 10px 24px -2px rgba(0,0,0,0.75)",
	fontSize: "20px",
	fontWeight: "bold",
};
const balaoInfoBottonStyle = {
	bgcolor: "gray",
	width: "max-content",
	borderRadius: "15px",
	p: 1,
	ml: "20px",
	mt: -5,
	position: "relative",
	color: "white",
	fontWeight: "bold",
	fontSize: "36px",
	boxShadow: "0px 15px 24px -5px rgb(0 0 0 / 75%)",
};

function ProductPath(props) {
	const itemPedido = props.itemPedido;
	const lote = props.lote;
	const pedido = itemPedido.pedido;
	console.log("lote");
	console.log(lote);
	const safra = lote?.loteSafras[0]?.safra;

	const cliente = pedido?.cliente;
	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: { xs: "100%", sm: "90vh" },
				m: 0,
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				flexDirection={{ xs: "row", sm: "row", md: "row" }}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					p: 0,
					m: 0,
					width: "100%",
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						m: 0,
						p: 2,
						width: "100%",
					}}
				>
					<Typography
						variant="h3"
						className={styles.titleProductPath}
						sx={{
							fontWeight: "800",
							color: "#009199",
						}}
					>
						Conheça o caminho do seu produto...
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						m: 0,
						p: 0,
						width: "100%",
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 1.5, md: 3 }}
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							p: 0,
							m: 0,
							height: "100%",
							width: "100%",
						}}
					>
						<Grid item xs={12} sm={6} md={3}>
							<Box sx={balaoInfoTopStyle}>Plantio</Box>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<h3>{new Date(safra?.dataPlantio).toLocaleDateString("pt-BR")}</h3>
									<p>
										Esta é a data em que o seu alimento foi plantado!
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>01</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Box sx={balaoInfoTopStyle}>Colheita</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>

									<h3>{new Date(lote?.dataColheita).toLocaleDateString("pt-BR")}</h3>
									<p>
										Esta é a data em que o seu alimento foi colhido!
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>02</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Box sx={balaoInfoTopStyle}>Distribuído</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<h3>{new Date(pedido?.data_entrega).toLocaleDateString("pt-BR")}</h3>
									<p>
									Esta é a data que seu alimento saiu do campo e foi para o próximo destino!
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>03</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Box sx={balaoInfoTopStyle}>Destino</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<h3>{cliente?.nome}</h3>
									<p>
										Este foi o destino que o alimento teve ao sair do campo.
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>04</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
export default memo(ProductPath);
