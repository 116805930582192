import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import styles from "./CultureEmbalagensCad.module.css";

import { Typography } from "@mui/material";
import { FarmService } from "../../../service/farm_service";
import { MeasurementUnitService } from "../../../service/measurement_unit_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	widths: "auto",
	m: 0,
	p: 0,
};

function CultureEmbalagensCad() {
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [farmPackage, setFarmPackage] = useState({});
	const [allMeasurementUnits, setAllMeasurementUnits] = useState([]);
	const [errorMessageEmbalagem, setErrorMessageEmbalagem] = useState(false);
	const [errorMessageMedida, setErrorMessageMedida] = useState(false);
	const [errorMessageQuantidade, setErrorMessageQuantidade] = useState(false);

	const url = new URLSearchParams(window.location.search);
	const id = url.get("id");
	const idReturn = url.get("idReturn");

	const measurementUnitService = new MeasurementUnitService();
	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		
		if(id){
			const farmPackageToEdit = await farmService.getPackageById(id);
			setFarmPackage(farmPackageToEdit);
		}

		let measurementUnits = await measurementUnitService.get();
		measurementUnits = measurementUnits.filter((measurementUnit) => measurementUnit.cultivo);
		setAllMeasurementUnits(measurementUnits);
	};
	
	const validationSave = async () => {
		if (
			farmPackage.nome &&
			farmPackage.nome !== "" &&
			farmPackage.unidadeMedidaID &&
			farmPackage.unidadeMedidaID !== "-1" &&
			farmPackage.quantidade &&
			farmPackage.quantidade > 0
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageEmbalagem(false);
			setErrorMessageMedida(false);
			setErrorMessageQuantidade(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (!farmPackage.nome || farmPackage.nome === "") {
				message += "Embalagem, ";
				setErrorMessageEmbalagem(true);
			} else {
				setErrorMessageEmbalagem(false);
			}
			if (!farmPackage.unidadeMedidaID || farmPackage.unidadeMedidaID === "-1") {
				message += "Unidade de medida, ";
				setErrorMessageMedida(true);
			} else {
				setErrorMessageMedida(false);
			}
			if (!farmPackage.quantidade || farmPackage.quantidade === 0) {
				message += "Quantidade, ";
				setErrorMessageQuantidade(true);
			} else {
				setErrorMessageQuantidade(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		if(id){
			const packageToEdit = {
				id: id,
				name: farmPackage.nome,
				quantity: farmPackage.quantidade,
				farmId: idReturn,
				measureUnityId: farmPackage.unidadeMedidaID
			};
			await farmService.updatePackage(packageToEdit);
		} else{
			const packageToSave = {
				name: farmPackage.nome,
				quantity: farmPackage.quantidade,
				farmId: idReturn,
				measureUnityId: farmPackage.unidadeMedidaID
			};
			await farmService.savePackage(packageToSave);
		}
	};

	return (
		<div>
			<TopMenu open={["register", "culture"]} help="cultureCad" />
			{/* href defini a volta  title defini o titulo da pagina*/}
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						sx={{ display: "flex", width: "100%" }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title="Cadastro de Embalagem"
									href={`/culturas/Embalagens/${idReturn}`}
									salveFunc={validationSave}
									typeMessage={typeMessage}
									message={message}
								>
									{/* inputs da pagina de resgitro */}
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageEmbalagem
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box
														sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m: 0														
														}}
													>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(farmPackage === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Nome da embalagem
														</Typography>
	
														<p
															className={
																errorMessageEmbalagem
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>

														<input
															value={farmPackage.nome}
															onChange={(event) =>
																setFarmPackage({...farmPackage, nome: event.target.value})
															}
															className={styles.inputEmbalagem}
															placeholder="Nome da embalagem"
															type="text"
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageQuantidade
															? styles.errControl
															: styles.noErrControl
													}
												>
													<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility: (farmPackage.quantidade ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Quantidade
														</Typography>
	
														<p
															className={
																errorMessageQuantidade
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>

														<input															
															className={styles.inputEmbalagem}
															type="number"
															placeholder="Quantidade"
															value={farmPackage.quantidade}
															onChange={(event) =>
																setFarmPackage({...farmPackage, quantidade: Number(event.target.value)})
															}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={4}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageMedida
															? styles.errControl
															: styles.noErrControl
													}
												>	<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(farmPackage.unidadeMedidaID && farmPackage.unidadeMedidaID !== "-1"
																	? "visible"
																	: "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Selecionar unidade de medida
														</Typography>
														<p
															className={
																errorMessageMedida
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<select
															id="medida"
															onChange={(event) => {
																const measurementUnit = allMeasurementUnits.find((measurementUnit) => measurementUnit.id === event.target.value);
																setFarmPackage({...farmPackage, unidadeMedidaID: event.target.value, unidadeMedida: measurementUnit});
															}}
															value={farmPackage.unidadeMedidaID}															
															className={styles.inputEmbalagem}
														>
															<option value={"-1"}>
																{farmPackage.unidadeMedidaID
																	? "---------------"
																	: "Selecionar unidade de medida"}
															</option>
															{allMeasurementUnits.map(
																(item, index) => {
																	return (
																		<option value={item.id} key={index}>
																			{item.sigla}
																		</option>
																	);
																}
															)}
														</select>
													</Box>
												</div>
											</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default CultureEmbalagensCad;
