import React from "react";
import InputMask from "react-input-mask";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const MaskedInput = (props) => {
	function handleChange(event) {
		props.onChange({
			...event,
			target: {
				...event.target,
				name,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return (
		<InputMask
			placeholder={props.placeholder}
			name={props.name}
			mask={props.mask}
			value={props.value}
			className={props.class}
			onChange={handleChange}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			disabled={props.disabled}
		/>
	);
};

export default MaskedInput;
