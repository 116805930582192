import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InputMask from "../../../components/InputMask";
import TopMenu from "../../../components/TopMenu";

//import css modules
/* import "./style.css"; */
import styles from "./UserCad.module.css";

//components
import RegisterArea from "../../../components/RegistrationArea";
// import CheckPropriedade from "../../../components/CheckPropriedade";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";
import { UserService } from "../../../service/user_service";
import { PropertyService } from "../../../service/property_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
};

function UserCad() {
	const [lancamento, setLancamento] = useState([
		{ id: 1, name: "Safra" },
		{ id: 2, name: "Manejo" },
		{ id: 3, name: "Pedidos" },
		{ id: 4, name: "Perdas" },
		{ id: 5, name: "Ocorrências" },
		{ id: 6, name: "Colheitas" },
	]);
	const [cadastro, setCadastro] = useState([
		{ id: 1, name: "Propriedade" },
		{ id: 2, name: "Usuário" },
		{ id: 3, name: "Cultura" },
		{ id: 4, name: "Serviços" },
		{ id: 5, name: "Clientes" },
		{ id: 6, name: "Insumos" },
	]);
	const [consulta, setConsulta] = useState([
		{ id: 1, name: "Relatórios" },
		{ id: 2, name: "Estoque" },
	]);
	const [outros, setOutros] = useState([
		{ id: 1, name: "Exclusão de dados" },
		{ id: 2, name: "Alterar logomarca" },
		{ id: 3, name: "Precificação" },
	]);
	const [propriedades, setPropriedades] = useState([]);
	const [id, setId] = useState(null);
	const [searchParams] = useSearchParams();
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const userIdHelpFilter = searchParams.get("id");
	const [errorMessageNome, setErrorMessageNome] = useState(false);
	const [errorMessageEmail, setErrorMessageEmail] = useState(false);
	const [errorMessagePhone, setErrorMessagePhone] = useState(false);
	const [errorMessageProperty, setErrorMessageProperty] = useState(false);
	const [user, setUser] = useState({});

	const userService = new UserService();
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const userId = searchParams.get("id");
		if (userId === null) {
			const businessProperties = await getPropertiesForBusiness();
			setPropriedades(businessProperties);
		} else {
			setId(userId);
			const userById = await userService.getById(userId);
			userById.telefone = userById.telefone.replace("+55", "");
			setUser(userById);
			setCadastro([
				{ id: 1, name: "Propriedade", isChecked: userById.permissao_propriedade },
				{ id: 2, name: "Usuário", isChecked: userById.permissao_usuario },
				{ id: 3, name: "Cultura", isChecked: userById.permissao_cultura },
				{ id: 4, name: "Serviços", isChecked: userById.permissao_servico },
				{ id: 5, name: "Clientes", isChecked: userById.permissao_cliente },
				{ id: 6, name: "Insumos", isChecked: userById.permissao_insumo },
			]);
			setConsulta([
				{ id: 1, name: "Relatórios", isChecked: userById.permissao_relatorio },
				{ id: 2, name: "Estoque", isChecked: userById.permissao_estoque },
			]);
			setLancamento([
				{ id: 1, name: "Safra", isChecked: userById.permissao_safra },
				{ id: 2, name: "Manejo", isChecked: userById.permissao_manejo },
				{ id: 3, name: "Pedidos", isChecked: userById.permissao_pedido },
				{ id: 4, name: "Perdas", isChecked: userById.permissao_perda },
				{ id: 5, name: "Ocorrências", isChecked: userById.permissao_ocorrencia },
				{ id: 6, name: "Colheitas", isChecked: userById.permissao_colheita },
			]);
			setOutros([
				{
					id: 1,
					name: "Exclusão de dados",
					isChecked: userById.permissao_exclusao,
				},
				{ id: 2, name: "Alterar logomarca", isChecked: userById.permissao_logo },
				{
					id: 3,
					name: "Precificação",
					isChecked: userById.permissao_precificacao,
				},
			]);

			setCheckOperador(userById.operador ?? false);

			const properties = await getPropertiesForUser(userById);
			setPropriedades(properties);
		}
	};

	const getPropertiesForUser = async (user) => {
		const properties = [];
		const businessProperty = await propertyService.getByBusiness(user.empresaId);
		for (let i = 0; i < businessProperty.length; i++) {
			const property = businessProperty[i];
			const userPropertyIndex = user.propriedades.findIndex((prop) => prop.propriedade.id === property.id);
			properties.push({
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: userPropertyIndex >= 0,
				changed: false,
			});
		}
		return properties;
	};

	const getPropertiesForBusiness = async () => {
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);
		const properties = businessProperty.map((property, i) => {
			return {
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: false,
				changed: false,
			};
		});
		return properties;
	};

	const handleChangeADM = (e) => {
		let aux = e;
		handleChangeCadastro(aux);
		handleChangeConsulta(aux);
		handleChangeOutros(aux);
		handleChangeLancamento(aux);
		setCheckOperador(false);	
	};
	const [checkOperador, setCheckOperador] = useState(false);
	const handleChangeOperador = (e) => {
		let aux = e;
		setCheckOperador(true);	
		handleChangeCadastro(aux);
		handleChangeConsulta(aux);
		handleChangeOutros(aux);
		handleChangeLancamento(aux);
	};
	const handleChangeLancamento = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			setCheckOperador(false);	
			let tempUser = lancamento.map((lancamento) => {
				return { ...lancamento, isChecked: checked };
			});
			setLancamento(tempUser);
		} else if (name === "allSelectOperador") {
			let tempUser = lancamento.map((lancamento) => {
				return { ...lancamento, isChecked: false };
			});
			setLancamento(tempUser);
		} else {
			setCheckOperador(false);	
			let tempUser = lancamento.map((lancamento) =>
				lancamento.name === name
					? { ...lancamento, isChecked: checked }
					: lancamento
			);
			setLancamento(tempUser);
		}
	};
	const handleChangeCadastro = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			setCheckOperador(false);	
			let tempCadastro = cadastro.map((cadastro) => {
				return { ...cadastro, isChecked: checked };
			});
			setCadastro(tempCadastro);
		}else if (name === "allSelectOperador") {
			let tempCadastro = cadastro.map((cadastro) => {
				return { ...cadastro, isChecked: false };
			});
			setCadastro(tempCadastro);
		} else {
			setCheckOperador(false);	
			let tempCadastro = cadastro.map((cadastro) =>
				cadastro.name === name ? { ...cadastro, isChecked: checked } : cadastro
			);
			setCadastro(tempCadastro);
		}
	};
	const handleChangeConsulta = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			setCheckOperador(false);	
			let tempConsulta = consulta.map((consulta) => {
				return { ...consulta, isChecked: checked };
			});
			setConsulta(tempConsulta);
		} else if (name === "allSelectOperador") {
			let tempConsulta = consulta.map((consulta) => {
				return { ...consulta, isChecked: false };
			});
			setConsulta(tempConsulta);
		} else {
			setCheckOperador(false);	
			let tempConsulta = consulta.map((consulta) =>
				consulta.name === name ? { ...consulta, isChecked: checked } : consulta
			);
			setConsulta(tempConsulta);
		}
		
	};
	const handleChangeOutros = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			setCheckOperador(false);	
			let tempoutros = outros.map((outros) => {
				return { ...outros, isChecked: checked };
			});
			setOutros(tempoutros);
		} else if (name === "allSelectOperador") {
			let tempoutros = outros.map((outros) => {
				return { ...outros, isChecked: false };
			});
			setOutros(tempoutros);
		} else {
			setCheckOperador(false);	
			let tempoutros = outros.map((outros) =>
				outros.name === name ? { ...outros, isChecked: checked } : outros
			);
			setOutros(tempoutros);
		}
	};
	const handlePropertyChangeAll = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			let tempPropriedade = propriedades.map((propriedades) => {
				let propried = propriedades;
				propried.selected = checked;
				propried.changed = checked;
				return { ...propried };
			});

			setPropriedades(tempPropriedade);
		}
	};

	const handlePropertyChange = (item) => {
		const newPropriedades = propriedades.slice();
		const property = propriedades[item.id];

		property.selected = !property.selected;
		property.changed = !property.changed;

		newPropriedades[item.id] = property;
		setPropriedades(newPropriedades);
	};
	const validationPropriedadeSelecionada = () => {
		let hasPropertySelected = false;
		for (let i = 0; i < propriedades.length; i++) {
			const property = propriedades[i];

			if (property.selected) hasPropertySelected = true;
		}
		return hasPropertySelected;
	};
	const validationSave = async () => {
		if (
			user.nome && user.nome !== "" &&
			validationPropriedadeSelecionada() &&
			((user.telefone && user.telefone !== "" && user.telefone.length >= 11) || (user.email && user.email !== "") || checkOperador === true)
		) {
			if((user.telefone && user.telefone !== "" && user.telefone.length >= 11) || user.telefone === null || user.telefone === undefined || user.telefone === ""){
				console.log(user.telefone);
				console.log(user.email);
				await handleSave();
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				setErrorMessageNome(false);
				setErrorMessageProperty(false);
				setErrorMessageEmail(false);
				setErrorMessagePhone(false);
				return true;
			}else{
				setErrorMessagePhone(true);
				let message = "Número de telefone inválido";
				setMessage(message);
			}
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (!user.nome || user.nome === "") {
				message += "Nome, ";
				setErrorMessageNome(true);
			} else {
				setErrorMessageNome(false);
			}
			if (!validationPropriedadeSelecionada()) {
				message += "Propriedade, ";
				setErrorMessageProperty(true);
			} else {
				setErrorMessageProperty(false);
			}
			if ((!user.email || user.email === "") && !id) {
				message += "Email, ";
				setErrorMessageEmail(true);
			} else {
				setErrorMessageEmail(false);
			}
			if ((!user.telefone || user.telefone === "" || user.telefone.length >= 11) && !id) {
				message += "Telefone, ";
				setErrorMessagePhone(true);
			} else {
				setErrorMessagePhone(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const isUserAdmin = () => {
		return isAllConsultaPermissonEnabled() && isAllLancamentoPermissonEnabled() && 
			isAllCadastroPermissonEnabled() && isAllOtherPermissonEnabled();
	};

	const isAllConsultaPermissonEnabled = () => {
		return !consulta.some((permission) => permission?.isChecked !== true);
	};

	const isAllLancamentoPermissonEnabled = () => {
		return !lancamento.some((user) => user?.isChecked !== true);
	};

	const isAllCadastroPermissonEnabled = () => {
		return !cadastro.some((user) => user?.isChecked !== true);
	};

	const isAllOtherPermissonEnabled = () => {
		return !outros.some((user) => user?.isChecked !== true);
	};

	const handleSave = async () => {
		const propertiesToSave = propriedades.filter((prop) => prop.changed && prop.selected);
		const propertiesToSaveId = propertiesToSave.map((prop) => prop.propertyId);

		const userToSave = {
			nome: user.nome,
			empresaId: user.empresaId,
			login: user.login,
			password: user.password,
			email: user.email,
			telefone: user.telefone ? `+55${user.telefone}` : null,
			operador: checkOperador,
			admin: isUserAdmin(),
			ativo: user.ativo,
			permissao_propriedade: cadastro[0].isChecked ?? false,
			permissao_usuario: cadastro[1].isChecked ?? false,
			permissao_cultura: cadastro[2].isChecked ?? false,
			permissao_servico: cadastro[3].isChecked ?? false,
			permissao_cliente: cadastro[4].isChecked ?? false,
			permissao_insumo: cadastro[5].isChecked ?? false,
			permissao_safra: lancamento[0].isChecked ?? false,
			permissao_manejo: lancamento[1].isChecked ?? false,
			permissao_pedido: lancamento[2].isChecked ?? false,
			permissao_perda: lancamento[3].isChecked ?? false,
			permissao_ocorrencia: lancamento[4].isChecked ?? false,
			permissao_colheita: lancamento[5].isChecked ?? false,
			permissao_relatorio: consulta[0].isChecked ?? false,
			permissao_estoque: consulta[1].isChecked ?? false,
			permissao_exclusao: outros[0].isChecked ?? false,
			permissao_logo: outros[1].isChecked ?? false,
			permissao_precificacao: outros[2].isChecked ?? false,
			includeProperties: propertiesToSaveId,
		};
		if (id) {
			const propertiesToRemove = propriedades.filter((prop) => prop.changed && !prop.selected);
			const propertiesToRemoveId = propertiesToRemove.map((prop) => prop.propertyId);

			userToSave.id = user.id;
			userToSave.removeProperties = propertiesToRemoveId;

			await userService.update(userToSave);

			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
		} else {
			const localUser = JSON.parse(localStorage.getItem("user"));

			userToSave.empresaId = localUser.businessId;
			userToSave.login = user.nome.replace(/ /g, "_").toLowerCase();
			userToSave.ativo = true;
			userToSave.password = Math.random().toString().slice(2, 10);

			await userService.save(userToSave);
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
		}
	};
	
	return (
		<div>
			<TopMenu
				open={["register", "users"]}
				help={userIdHelpFilter === null ? "usersCad" : "userEdit"}
			/>
			{/* href defini a volta  title defini o titulo da pagina*/}
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title={
										userIdHelpFilter === null
											? "Cadastro de usuário"
											: "Editar usuário"
									}
									href="/usuarios"
									dataCriacao={true}
									dataAlteracao={true}
									typeMessage={typeMessage}
									message={message}
									salveFunc={validationSave}
								>
									{/* inputs da pagina de resgitro */}
									<Grid
										container
										rowSpacing={3}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12} sx={stylesItem}>
											<div
												className={
													errorMessageNome
														? styles.errControl
														: styles.noErrControl
												}
											>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(user.nome === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Nome
													</Typography>

													<p
														className={
															errorMessageNome ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
													<input 
														className={styles.testInput} 
														type="text" 
														placeholder="Nome" 
														id="nome"  
														value={user.nome} 
														onChange={(e)=> setUser({...user, nome: e.target.value})}/>
												</Box>
											</div>
											<div className={styles.checkboxContainer}>
												<input
													type="checkbox"
													className={styles.inputCheckbox}
													name="allSelect"
													id="adm"
													checked={
														isUserAdmin()
													}
													onChange={handleChangeADM}
												/>
												<label htmlFor="adm">ADM</label>
												
											</div>
											<div className={styles.checkboxContainer}>
												<input
													type="checkbox"
													className={styles.inputCheckbox}
													id="operador"
													name="allSelectOperador"
													checked={
														!outros.some((user) => user?.isChecked !== false) &&
														!cadastro.some(
															(user) => user?.isChecked !== false
														) &&
														!lancamento.some(
															(user) => user?.isChecked !== false
														) &&
														!consulta.some((user) => user?.isChecked !== false)
													}
													onChange={handleChangeOperador}
												/>
												<label htmlFor="operador">Operador inativo</label>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageEmail
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(user.email === ""?"hidden":"visible"),transition:"all 0.5s ease"}} 
														>
															E-mail
														</Typography>	
														<p
															className={
																errorMessageEmail
																	? styles.errText
																	: styles.noErrText
															}
														>
															*
														</p>
														<input
															type="email"
															placeholder="Email"
															id="email"
															value={user.email} 
															onChange={(e)=> setUser({...user, email: e.target.value})}
															disabled={checkOperador}
															className={styles.testInput}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessagePhone
															? styles.errControl
															: styles.noErrControl
													}
												>	<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 0,
														pr:3,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(user.telefone === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Telefone
														</Typography>
	
														<p
															className={
																errorMessagePhone
																	? styles.errText
																	: styles.noErrText
															}
														>
															*
														</p>
														<InputMask
															class={styles.testInput}
															placeholder="Telefone"
															mask="(99)99999-9999"
															value={user.telefone}
															onChange={(e) => setUser({...user, telefone: e.target.value})}
															disabled={checkOperador}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
									</Grid>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<Grid
													container
													rowSpacing={1}
													columnSpacing={{ xs: 1, sm: 2, md: 3 }}
												>
													<Grid item xs={12} sm={12} md={12}>
														<Item sx={stylesItem}>
															<div className={styles.registrationTitle}>
																<p>Permissões</p>
															</div>
															<p
																className={
																	errorMessageProperty
																		? styles.errText
																		: styles.noErrText
																}
															>
																Selecione no minimo 1 Propriedade *
															</p>
														</Item>
													</Grid>
													<Grid item xs={12} sm={6} md={2.4}>
														<Item
															sx={{
																// border: "1px solid #b1b1b1",
																boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
																height: "100%",
															}}
														>
															<div className={styles.checkboxContainer}>
																<input
																	type="checkbox"
																	className={styles.inputCheckbox}
																	name="allSelect"
																	id="propriedade"
																	checked={
																		!propriedades.some(
																			(propriedade) =>
																				propriedade.selected !== true
																		)
																	}
																	onChange={(e) => handlePropertyChangeAll(e)}
																/>
																<label
																	htmlFor="propriedade"
																	className={styles.labelTitle}
																>
																	<b>Propriedades</b>
																</label>
															</div>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
															>
																{propriedades.map((propriedade, index) => (
																	<Grid
																		item
																		xs={12}
																		sm={12}
																		md={12}
																		key={index}
																	>
																		<Item
																			htmlFor={propriedade.id}
																			sx={{
																				display: "flex",
																				justifyContent: "start",
																				p: 0,
																				m: 0,
																			}}
																		>
																			<div className={styles.checkboxContainer}>
																				<input
																					type="checkbox"
																					className={styles.inputCheckbox}
																					id={propriedade.id}
																					name={propriedade.nome}
																					checked={propriedade.selected}
																					onChange={() =>
																						handlePropertyChange(propriedade)
																					}
																				/>
																				<label
																					htmlFor={propriedade.id}
																					className={
																						styles.CheckPropriedadeLabel
																					}
																				>
																					{propriedade.nome}
																				</label>
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
													<Grid item xs={12} sm={6} md={2.4}>
														<Item
															sx={{
																// border: "1px solid #b1b1b1",
																boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
																height: "100%",
															}}
														>
															<div className={styles.checkboxContainer}>
																<input
																	type="checkbox"
																	className={styles.inputCheckbox}
																	name="allSelect"
																	id="cadastros"
																	checked={
																		!cadastro.some(
																			(user) => user?.isChecked !== true
																		)
																	}
																	onChange={handleChangeCadastro}
																/>
																<label
																	htmlFor="cadastros"
																	className={styles.labelTitle}
																>
																	<b>Cadastros</b>
																</label>
															</div>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
															>
																{cadastro.map((cadastro, index) => (
																	<Grid
																		item
																		xs={12}
																		sm={12}
																		md={12}
																		key={index}
																	>
																		<Item
																			htmlFor={cadastro.id}
																			sx={{
																				display: "flex",
																				justifyContent: "start",
																				p: 0,
																				m: 0,
																			}}
																		>
																			<div className={styles.checkboxContainer}>
																				<input
																					type="checkbox"
																					className={styles.inputCheckbox}
																					id={cadastro.name}
																					name={cadastro.name}
																					checked={cadastro?.isChecked || false}
																					onChange={handleChangeCadastro}
																				/>
																				<label
																					htmlFor={cadastro.name}
																					className={
																						styles.CheckPropriedadeLabel
																					}
																				>
																					{cadastro.name}
																				</label>
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
													<Grid item xs={12} sm={4} md={2.4}>
														<Item
															sx={{
																// border: "1px solid #b1b1b1",
																boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
																height: "100%",
															}}
														>
															<div className={styles.checkboxContainer}>
																<input
																	type="checkbox"
																	className={styles.inputCheckbox}
																	name="allSelect"
																	id="lancamentos"
																	checked={
																		!lancamento.some(
																			(user) => user?.isChecked !== true
																		)
																	}
																	onChange={handleChangeLancamento}
																/>
																<label
																	htmlFor="lancamentos"
																	className={styles.labelTitle}
																>
																	<b>Lançamentos</b>
																</label>
															</div>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
															>
																{lancamento.map((lancamento, index) => (
																	<Grid
																		item
																		xs={12}
																		sm={12}
																		md={12}
																		key={index}
																	>
																		<Item
																			htmlFor={lancamento.id}
																			sx={{
																				display: "flex",
																				justifyContent: "start",
																				p: 0,
																				m: 0,
																			}}
																		>
																			<div className={styles.checkboxContainer}>
																				<input
																					type="checkbox"
																					className={styles.inputCheckbox}
																					id={lancamento.name}
																					name={lancamento.name}
																					checked={
																						lancamento?.isChecked || false
																					}
																					onChange={handleChangeLancamento}
																				/>
																				<label htmlFor={lancamento.name}>
																					{lancamento.name}
																				</label>
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
													<Grid item xs={12} sm={4} md={2.4}>
														<Item
															sx={{
																// border: "1px solid #b1b1b1",
																boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
																height: "100%",
															}}
														>
															<div className={styles.checkboxContainer}>
																<input
																	type="checkbox"
																	className={styles.inputCheckbox}
																	name="allSelect"
																	id="Consultas"
																	checked={
																		!consulta.some(
																			(user) => user?.isChecked !== true
																		)
																	}
																	onChange={handleChangeConsulta}
																/>
																<label
																	htmlFor="Consultas"
																	className={styles.labelTitle}
																>
																	<b>Consultas</b>
																</label>
															</div>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
															>
																{consulta.map((consulta, index) => (
																	<Grid
																		item
																		xs={12}
																		sm={12}
																		md={12}
																		key={index}
																	>
																		<Item
																			htmlFor={consulta.id}
																			sx={{
																				display: "flex",
																				justifyContent: "start",
																				p: 0,
																				m: 0,
																			}}
																		>
																			<div className={styles.checkboxContainer}>
																				<input
																					type="checkbox"
																					className={styles.inputCheckbox}
																					id={consulta.name}
																					name={consulta.name}
																					checked={consulta?.isChecked || false}
																					onChange={handleChangeConsulta}
																				/>
																				<label htmlFor={consulta.name}>
																					{consulta.name}
																				</label>
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
													<Grid item xs={12} sm={4} md={2.4}>
														<Item
															sx={{
																// border: "1px solid #b1b1b1",
																boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
																height: "100%",
															}}
														>
															<div className={styles.checkboxContainer}>
																<input
																	type="checkbox"
																	className={styles.inputCheckbox}
																	name="allSelect"
																	id="Outros"
																	checked={
																		!outros.some(
																			(user) => user?.isChecked !== true
																		)
																	}
																	onChange={handleChangeOutros}
																/>
																<label
																	htmlFor="Outros"
																	className={styles.labelTitle}
																>
																	<b>Outros</b>
																</label>
															</div>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
															>
																{outros.map((outros, index) => (
																	<Grid
																		item
																		xs={12}
																		sm={12}
																		md={12}
																		key={index}
																	>
																		<Item
																			htmlFor={outros.id}
																			sx={{
																				display: "flex",
																				justifyContent: "start",
																				p: 0,
																				m: 0,
																			}}
																		>
																			<div className={styles.checkboxContainer}>
																				<input
																					type="checkbox"
																					className={styles.inputCheckbox}
																					id={outros.name}
																					name={outros.name}
																					checked={outros?.isChecked || false}
																					onChange={handleChangeOutros}
																				/>
																				<label htmlFor={outros.name}>
																					{outros.name}
																				</label>
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
												</Grid>
											</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default UserCad;
