/* eslint-disable react/prop-types */
import * as Button from "../../components/Buttons";
import React from "react";

//material ul imports
import { IconAdd } from "../IconsImport";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

import "./style.css";

const styles = {
	bgcolor: "rgba(0, 0, 0, 0)",
	boxShadow: "none",
	width: "100%",
	m: 0,
	p: 0,
};
function SearchHeader(props) {
	return (
		<Box component="main" sx={{ width: "100%" }}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={12} sm={12} md={2}>
					<Item sx={styles}>
						{props.title !== undefined ? (
							<h2>{props.title}</h2>
						) : (
							props.headerComponents
						)}
					</Item>
				</Grid>
				<Grid item xs={6} sm={8} md={8}>
					<Item sx={styles}>{props.children}</Item>
				</Grid>
				<Grid item xs={6} sm={4} md={2}>
					<Item sx={styles}>
						{props.button === undefined ? (
							props.textButton !== undefined ? (
								<>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={styles}>
												<Button.GenericButtonGreen
													href={props.href}
													funcClick={props.funcClick}
													text={props.textButton}
													icon={
														props.icon !== undefined ? props.icon : <IconAdd />
													}
												/>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={styles}>{props.extraButton}</Item>
										</Grid>
									</Grid>
								</>
							) : (
								""
							)
						) : (
							props.button
						)}
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}

export default SearchHeader;
