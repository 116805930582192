import MailIcon from "@mui/icons-material/Mail";
import {
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Popover,
	Typography
} from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";

import { data } from "./dataNotificationTest";
import { NotificationService } from "../../../service/notification_service";

function notificationsLabel(count) {

	if (count === 0) return "sem notificações";

	if (count > 99) return "mais que 99 notifiações";

	return `${count} notificações`;
}

function AccessibleBadges(props) {
	const [notificacaoes, setNotificacaoes] = useState([{
		title:"",
		description:"",
		date: "",
	}]);
	const notificationService = new NotificationService();

	useEffect(() => {
		initData();		
	},[]);

	async function initData() { 
		const propertyId = localStorage.getItem("propertyId");
		setNotificacaoes(await notificationService.get(propertyId));
	}
	
	const show = props.show;
	return (
		<>
			<PopupState variant="popover" sx={{ width: "50%" }}>
				{(popupState) => (
					<div>
						{show === "all" ? (
							<Button
								sx={{
									borderRadius: "100%",
									width: 60,
									height: 60,
									p: 0,
									m: 0,
									bgcolor: "#00A69A",
									color: "white",
								}}
								{...bindTrigger(popupState)}
							>
								<IconButton aria-label={notificationsLabel(notificacaoes.length)}>
									<Badge badgeContent={notificacaoes.length} color="warning">
										<MailIcon sx={{ fontSize: "30px", color: "white" }} />
									</Badge>
								</IconButton>
							</Button>
						) : (
							<Button
								sx={{
									width: " 100%",
									bgcolor: "white",
									height: " 100%",
									p: 0,
									m: 0,
									color: "gray",
								}}
								{...bindTrigger(popupState)}
							>
								<Badge badgeContent={data.length} color="warning">
									<MailIcon />
									<Typography sx={{ textTransform: "none", pl: 1 }}>
										Notificação
									</Typography>
								</Badge>
							</Button>
						)}

						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{
								vertical: props.verticalAnchorOrigin,
								horizontal: props.horizontalAnchorOrigin,
							}}
							transformOrigin={{
								vertical: props.verticalTransformOrigin,
								horizontal: props.horizontalTransformOrigin,
							}}
						>
							<List
								sx={{
									width: "100%",
									maxWidth: 360,
									bgcolor: "background.paper",
									maxHeight: "50vh",
								}}
							>
								{notificacaoes.length > 0 ?
									(notificacaoes.map((item, index) => {
										return (
											<div key={item.id} >
												<ListItem												
													id={item.cuture+item.id}
													alignItems="flex-start"
												>
													{/* <ListItemAvatar>
													<Avatar
														alt={item.cuture}
														src="/static/images/avatar/1.jpg"
													/>
												</ListItemAvatar> */}
													<ListItemText
														id={index}													
														primary={item.title}//titulo da notificaçãos
														secondary={
															<React.Fragment id={index}>
																<Typography
																	id={index}
																	sx={{ display: "inline" , cursor: "pointer" }}
																	component="span"
																	variant="body2"
																	color="text.primary"
																// sx={{ cursor: "pointer" }}
																>
																	{item.description}
																	{/*autor ou ato da notificação*/}
																</Typography>
														
															</React.Fragment>
														}
													/>
												</ListItem>
												<Divider variant="inset" component="li" />
											</div>
										);
									})
									)
									: (
										<ListItem												
											alignItems="flex-start"
										>
											{/* <ListItemAvatar>
													<Avatar
														alt={item.cuture}
														src="/static/images/avatar/1.jpg"
													/>
												</ListItemAvatar> */}
											<ListItemText
												id={0}													
												primary={"Notificações"}//titulo da notificaçãos
												secondary={
													<React.Fragment id={0} 	>
														<Typography
															id={0}
															sx={{ display: "inline" , cursor: "pointer" }}
															component="span"
															variant="body2"
															color="text.primary"
															// sx={{ cursor: "pointer" }}
														>
																Você não tem notificações
															{/*autor ou ato da notificação*/}
														</Typography>
														
													</React.Fragment>
												}
											/>
										</ListItem>
									)}
							</List>
						</Popover>
					</div>
				)}
			</PopupState>
		</>
	);
}
export default AccessibleBadges;
