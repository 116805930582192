import { HarvestRepository } from "../repository/harvest_repository";

export class HarvestService {

	constructor() {
		this.harvestRepository = new HarvestRepository();
	}

	async save(harvest) {
		const savedHarvest = await this.harvestRepository.save(harvest);
		return savedHarvest;
	}

	async update(harvest) {
		const updatedHarvest = await this.harvestRepository.update(harvest);
		return updatedHarvest;
	}

	async getByProperty(propertyId, params) {
		const harvestData = await this.harvestRepository.getByProperty(propertyId, params);
		return harvestData.harvests;
	}

	async getById(harvestId) {
		const harvest = await this.harvestRepository.getById(harvestId);
		return harvest;
	}

	async delete(harvestId) {
		const harvest = await this.harvestRepository.delete(harvestId);
		return harvest;
	}
}