import axios from "axios";
export class ReportRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async getFieldNotebookGraphicData(params) {
		try {
			const result = await this.axios.get("report/field-notebook-graphic", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getFieldNotebookData(params) {
		try {
			const result = await this.axios.get("report/field-notebook-pdf", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getProductionBalanceGraphicData(params) {
		try {
			const result = await this.axios.get("report/production-balance-graphic", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getProductionBalanceData(params) {
		try {
			const result = await this.axios.get("report/production-balance-pdf", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getActivityData(params) {
		try {
			const result = await this.axios.get("report/activity-pdf", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getSalesData(params) {
		try {
			const result = await this.axios.get("report/sales-pdf", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getProductivityGraphicData(params) {
		try {
			const result = await this.axios.get("report/productivity-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getProductivityData(params) {
		try {
			const result = await this.axios.get("report/productivity-pdf", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getLossByFarmGraphicData(params) {
		try {
			const result = await this.axios.get("report/loss-by-farm-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getLossFromFarmGraphicData(params) {
		try {
			const result = await this.axios.get("report/loss-from-farm-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getLossByCropSeasonGraphicData(params) {
		try {
			const result = await this.axios.get("report/loss-by-cropseason-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getLossData(params) {
		try {
			const result = await this.axios.get("report/loss-pdf", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getIncidentByPlotGraphicData(params) {
		try {
			const result = await this.axios.get("report/incident-by-plot-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getIncidentBySubdivisionGraphicData(params) {
		try {
			const result = await this.axios.get("report/incident-by-subdivision-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getIncidentByCropSeasonGraphicData(params) {
		try {
			const result = await this.axios.get("report/incident-by-cropseason-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getIncidentByTypeGraphicData(params) {
		try {
			const result = await this.axios.get("report/incident-by-type-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getIncidentData(params) {
		try {
			const result = await this.axios.get("report/incident-pdf", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getStockInputOutputGraphicData(params) {
		try {
			const result = await this.axios.get("report/stock-input-output-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getStockUsedGraphicData(params) {
		try {
			const result = await this.axios.get("report/stock-used-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getStockData(params) {
		try {
			const result = await this.axios.get("report/stock-pdf", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getActivityByServiceGraphicData(params) {
		try {
			const result = await this.axios.get("report/activity-by-service-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getActivityByOperatorGraphicData(params) {
		try {
			const result = await this.axios.get("report/activity-by-operator-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getSalesByProductGraphicData(params) {
		try {
			const result = await this.axios.get("report/sales-by-product-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getSalesByCustomerGraphicData(params) {
		try {
			const result = await this.axios.get("report/sales-by-customer-graphic", {
				params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getGeneralData(params) {
		try {
			const result = await this.axios.get("report/general-pdf", {
				params,
				headers: {
					params: params,
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}