import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TopMenu from "../../../components/TopMenu";
import styles from "./InsumosCad.module.css";

//components
import HelpButtonInput from "../../../components/HelpButtonInput";
import RegisterArea from "../../../components/RegistrationArea";
//material ul imports
import { Autocomplete, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ptBR } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { PropertyService } from "../../../service/property_service";
import { MeasurementUnitService } from "../../../service/measurement_unit_service";
import { PropertyInputService } from "../../../service/property_input_service";
import { GroupInputService } from "../../../service/group_input_service";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
// const stylesItem = {
// 	bgcolor: "rgba(0, 0, 0, 0)",
// 	border: "none",
// 	boxShadow: "none",
// 	display: "flex",
// 	alignItems: "stretch",
// 	justifyContent: "stretch",
// 	widths: "100%",
// 	m: 0,
// 	p: 0,
// 	minWidth: "45vw",
// };

function InsumosCad() {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const [allInsumos, setAllInsumos] = useState([]);
	const [inputsToShow, setInputsToShow] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [allMeasurementUnits, setAllMeasurementUnits] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [propriedades, setPropriedades] = useState([]);
	const [errorMessageCustoUnitario, setErrorMessageCustoUnitario] =
		useState(false);
	const [errorMessageInsumo, setErrorMessageInsumo] = useState(false);
	const [errorMessageUnidade, setErrorMessageUnidade] = useState(false);
	const [inputInProperty, setInputInProperty] = useState({grupoInsumo: null, insumo: null});

	const groupInputService = new GroupInputService();
	const propertyInputService = new PropertyInputService();
	const propertyService = new PropertyService();
	const measurementUnitService = new MeasurementUnitService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {

		let groups = await groupInputService.get();
		groups = groups.map((item, index) => {
			return {...item,
				index: index,
				firstLetter: item.nome[0].toUpperCase(),
			};
		});
		setAllGroups(groups);

		let inputs = await groupInputService.getInputs();
		inputs = inputs.map((item, index) => {
			return {...item,
				index: index,
				firstLetter: item.nome[0].toUpperCase(),
			};
		});
		setAllInsumos(inputs);
		setInputsToShow(inputs);

		const measurementUnits = await measurementUnitService.get();
		setAllMeasurementUnits(measurementUnits);

		if(id) {
			let input = await propertyInputService.getById(id);

			const selectedInput = inputs.find((inp) => inp.id === input.insumoId);
			const selectedGroup = groups.find((group) => group.id === input.grupoInsumoId);
			
			setInputsToShow(inputs.filter((input) => {
				const groups = input.gruposInsumo.filter((group) => group.grupoInsumo.id === selectedGroup.id);
				return groups.length > 0;
			}));
			
			input = {...input,
				insumo: selectedInput,
				insumoId: selectedInput.id,
				grupoInsumo: selectedGroup,
				grupoInsumoId: selectedGroup.id
			};

			setInputInProperty(input);

			const properties = await getPropertiesForInput(input);
			setPropriedades(properties);
		} else {
			const properties = await getPropertiesForBusiness();
			setPropriedades(properties);
		}
	};

	const getPropertiesForBusiness = async () => {
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);
		const properties = businessProperty.map((property, i) => {
			return {
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: false,
				changed: false,
			};
		});
		return properties;
	};

	const getPropertiesForInput = async (input) => {
		const properties = [];
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);

		for (let i = 0; i < businessProperty.length; i++) {
			const property = businessProperty[i];
			const inputPropertyIndex = input.propriedades.findIndex((prop) => prop.propriedade.id === property.id);
			properties.push({
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: inputPropertyIndex >= 0,
				changed: false,
			});
		}
		return properties;
	};

	const validationSave = async () => {
		if (inputInProperty.insumoId !== "" && inputInProperty.custoUnitario > 0 && inputInProperty.unidadeMedidaId) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (inputInProperty.custoUnitario === 0) {
				message += "Custo unitário, ";
				setErrorMessageCustoUnitario(true);
			} else {
				setErrorMessageCustoUnitario(false);
			}
			if (inputInProperty.insumoId === "") {
				message += "Insumo, ";
				setErrorMessageInsumo(true);
			} else {
				setErrorMessageInsumo(false);
			}
			if (inputInProperty.unidadeMedidaId === null) {
				message += "Unidade de medida ";
				setErrorMessageUnidade(true);
			} else {
				setErrorMessageUnidade(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const propertiesToSave = propriedades.filter((prop) => prop.changed && prop.selected);
		const propertiesToSaveId = propertiesToSave.map((prop) => prop.propertyId);

		let propertyInputToSave = {
			unitCost: inputInProperty.custoUnitario,
			lackReapplicationDays: inputInProperty.careanciaReaplicacaoDias,
			lackHarvestDays: inputInProperty.carenciaColheitasDias,
			measurementUnitId: inputInProperty.unidadeMedidaId,
			inputId: inputInProperty.insumoId,
			groupInputId: inputInProperty.grupoInsumoId,
			includeProperties: propertiesToSaveId,
		};
		if (!id){
			await propertyInputService.save(propertyInputToSave);
		} else {
			const propertiesToRemove = propriedades.filter((prop) => prop.changed && !prop.selected);
			const propertiesToRemoveId = propertiesToRemove.map((prop) => prop.propertyId);

			propertyInputToSave.id = id;
			propertyInputToSave.removeInputProperties = propertiesToRemoveId;
			
			await propertyInputService.update(propertyInputToSave);
		}
	};

	const handlePropertyChangeAll = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			let tempPropriedade = propriedades.map((propriedades) => {
				let propried = propriedades;
				propried.selected = checked;
				propried.changed = checked;
				return propried;
			});
			setPropriedades(tempPropriedade);
		}
	};

	const handlePropertyChange = (item) => {
		const newPropriedades = propriedades.slice();
		const property = propriedades[item.id];
		property.selected = !property.selected;
		property.changed = !property.changed;
		newPropriedades[item.id] = property;
		setPropriedades(newPropriedades);
	};

	return (
		<div>
			<TopMenu
				open={["register", "insumos"]}
				help={id === null ? "insumosCad" : "insumosEdit"}
			/>
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title={
										id === null
											? "Cadastro de Insumo"
											: "Editar Insumo"
									}
									href="/insumos"
									salveFunc={validationSave}
									typeMessage={typeMessage}
									message={message}
								>
									{/* inputs da pagina de resgitro */}
									<Grid
										container
										rowSpacing={2}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={6}>
											<	Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "column",
												alignItems: "flex-start",
												px: 1,
												pr:2,
												position: "relative",
												m:0
											}}>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-11,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(inputInProperty.grupoInsumoId === null? "hidden" : "visible"),
														transition:"all 0.5s ease"
													}} 
												>
													Grupo
												</Typography>

												<ThemeProvider theme={theme}>
													<Autocomplete
														value={inputInProperty.grupoInsumo}
														onChange={(event, newValue) => {
															setInputInProperty({...inputInProperty, grupoInsumoId: newValue.id, grupoInsumo: newValue});
															if (newValue.id) {
																setInputsToShow(allInsumos.filter((input) => {
																	const groups = input.gruposInsumo.filter((group) => group.grupoInsumo.id === newValue.id);
																	return groups.length > 0;
																}));
															} else {
																setInputsToShow(allInsumos);
															}
														}}
														options={allGroups.sort(
															(a, b) =>
																-b.firstLetter.localeCompare(a.firstLetter)
														)}
														groupBy={(option) => option.firstLetter}
														getOptionLabel={(option) => option.nome}
														sx={{ width: "100%", border: "none" }}
														renderInput={(params) => (
															<div ref={params.InputProps.ref}>
																<input
																	type="text"
																	{...params.inputProps}
																	placeholder="Grupo"
																	className={styles.inputInsumo}
																/>
															</div>
														)}
													/>
												</ThemeProvider>
											</Box>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Box	Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "column",
												alignItems: "flex-start",
												px: 1,
												pr:2,
												position: "relative",
												m:0
											}}>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-11,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(inputInProperty.unidadeMedidaId === null ? "visible" : "hidden"),
														transition:"all 0.5s ease"
													}} 
												>
													Unidade de medida
												</Typography>
												<div
													className={
														errorMessageUnidade
															? styles.errControl
															: styles.noErrControl
													}
												>
													<p
														className={
															errorMessageUnidade
																? styles.errText
																: styles.noErrText
														}
													>
													*
													</p>
													<select
														id="medida"
														onChange={(event) => {
															const measurementUnit = allMeasurementUnits.find((measurementUnit) => measurementUnit.id === event.target.value);
															setInputInProperty({...inputInProperty, unidadeMedidaId: event.target.value, unidadeMedida: measurementUnit});
														}}
														value={inputInProperty.unidadeMedidaId}															
														className={styles.inputEmbalagem}
													>
														<option value={"-1"}>
															{inputInProperty.unidadeMedidaId
																? "---------------"
																: "Selecionar unidade de medida"}
														</option>
														{allMeasurementUnits.map(
															(item, index) => {
																return (
																	<option value={item.id} key={index}>
																		{item.sigla}
																	</option>
																);
															}
														)}
													</select>
												</div>
											</Box>
										</Grid>
										<Grid item xs={12} sm={12} md={6} sx={{display:"flex"}}>
											<Box	Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "row",
												alignItems: "flex-start",
												px: 1,
												pr:2,
												position: "relative",
												m:0
												
											}}>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-11,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(inputInProperty.insumoId ? "visible" : "hidden"),
														transition:"all 0.5s ease"
													}} 
												>
													Insumo
												</Typography>
												<div
													className={
														errorMessageInsumo
															? styles.errControl
															: styles.noErrControl
													}
												>
													<ThemeProvider theme={theme}>
														<Autocomplete
															value={inputInProperty.insumo}
															onChange={(event, newValue) => {
																const groupInput = allGroups.find((group) => group.id === newValue.gruposInsumo[0].grupoInsumoId);
																setInputInProperty({
																	...inputInProperty, 
																	grupoInsumoId: newValue.gruposInsumo[0].grupoInsumoId,
																	insumoId: newValue.id, 
																	insumo: newValue,
																	grupoInsumo: groupInput
																});
															}}
															options={inputsToShow.sort(
																(a, b) =>
																	-b.firstLetter.localeCompare(a.firstLetter)
															)}
															groupBy={(option) => option.firstLetter}
															getOptionLabel={(option) => option.nome}
															sx={{ width: "100%", border: "none" }}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input
																		type="text"
																		{...params.inputProps}
																		placeholder="Insumo"
																		className={styles.inputInsumo}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
													<p
														className={
															errorMessageInsumo
																? styles.errText
																: styles.noErrText
														}
													>
														*
													</p>
												</div>
											</Box>
											<HelpButtonInput
												title={"INSUMOS "}
												text={
													"Escolha o nome comercial do produto ser registrado. Se o produto não estiver na listagem faça uma solicitação de adição através do suporte informando o nome comercial do produto e se possível, envie uma imagem da embalagem. Suporte: Whatsapp ; (85) 98131-0242; E-mail: "
												}
												link={"Suporte.sisagri@gmail.com"}
											/>
											
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<div
												className={
													errorMessageCustoUnitario
														? styles.errControl
														: styles.noErrControl
												}
											>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "row",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(inputInProperty.custoUnitario ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Custo unitário
													</Typography>
													<input
														type="number"
														placeholder="Custo unitário"
														id="custoUnitario"
														value={inputInProperty.custoUnitario}
														onChange={e => setInputInProperty({...inputInProperty, custoUnitario: e.target.value})}
														className={styles.inputInsumo}
													/>
													<p
														className={
															errorMessageCustoUnitario
																? styles.errText
																: styles.noErrText
														}
													>
													*
													</p>
												</Box>
												<HelpButtonInput
													title={"Custo unitário"}
													text={
														"Adicione o preço da unidade de medida do produto comercial. Ex.: Se o produto apresenta um custo de $20,00 e apresenta uma embalagem contendo 2kg, logo o valor a ser informado será: $10,00 (20/2 = 10)."
													}
												/>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} sx={{display:"flex"}}>
											<Box	Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "row",
												alignItems: "flex-start",
												px: 1,
												pr:2,
												position: "relative",
												m:0
												
											}}>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-11,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(inputInProperty.careanciaReaplicacaoDias ? "visible" : "hidden"),
														transition:"all 0.5s ease"
													}}
												>
													Intervalo para nova aplicação
												</Typography>
												<input
													value={inputInProperty.careanciaReaplicacaoDias}
													onChange={e => setInputInProperty({...inputInProperty, careanciaReaplicacaoDias: e.target.value})}
													type="text"
													placeholder="Intervalo para nova aplicação"
													id="intervalo"
													className={styles.inputInsumo}
												/>
											</Box>
											<HelpButtonInput
												title={"INTERVALO PARA NOVA APLICAÇÃO"}
												text={
													"Registre o intervalo de aplicação, em dias, informado pela fabricante. Vide bula do produto."
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={6}  sx={{display:"flex"}}>
											<Box	Box sx={{
												border: "1px solid rgba(0,0,0,0.3)",
												borderRadius: "8px",
												width: "100%",
												display: "flex",
												justifyContent: "flex-start",
												flexDirection: "row",
												alignItems: "flex-start",
												px: 1,
												pr:2,
												position: "relative",
												m:0
												
											}}>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-11,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(inputInProperty.carenciaColheitasDias ? "visible" : "hidden"),
														transition:"all 0.5s ease"}} >
														Carência
												</Typography>
												<input
													value={inputInProperty.carenciaColheitasDias}
													onChange={e => setInputInProperty({...inputInProperty, carenciaColheitasDias: e.target.value})}
													type="text"
													placeholder="Carência"
													id="carencia"
													className={styles.inputInsumo}
												/>
											</Box>
											<HelpButtonInput
												title={"CARÊNCIA"}
												text={
													"Registre o intervalo de segurança para colheita após aplicação do produto, em dias, informado pela fabricante. Vide bula do produto."
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item
												sx={{
													// border: "1px solid #b1b1b1",
													boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													height: "100%",
												}}
											>
												<p className={styles.labelTitle}>
													<b>Propriedades</b>
												</p>
												<div className={styles.checkboxContainer}>
													<input
														type="checkbox"
														className={styles.inputCheckbox}
														name="allSelect"
														id="propriedade"
														checked={
															!propriedades.some(
																(propriedade) => propriedade.selected !== true
															)
														}
														onChange={(e) => handlePropertyChangeAll(e)}
													/>
													<label
														htmlFor="propriedade"
														className={styles.labelTitle}
													>
														<b>
															Cadastre o insumo simultaneamente na suas outras
															propriedades
														</b>
													</label>
												</div>
												<Grid
													container
													rowSpacing={1}
													columnSpacing={{ xs: 1, sm: 2, md: 3 }}
												>
													{propriedades.map((propriedade, index) => (
														<Grid
															item
															xs={"auto"}
															key={index}
															sx={{ minWidth: "250px" }}
														>
															<Item
																htmlFor={propriedade.id}
																sx={{
																	display: "flex",
																	justifyContent: "start",
																	p: 0,
																	m: 0,
																}}
															>
																<div className={styles.checkboxContainer}>
																	<input
																		type="checkbox"
																		className={styles.inputCheckbox}
																		id={propriedade.id}
																		name={propriedade.nome}
																		checked={propriedade.selected}
																		onChange={() =>
																			handlePropertyChange(propriedade)
																		}
																	/>
																	<label
																		htmlFor={propriedade.id}
																		className={styles.CheckPropriedadeLabel}
																	>
																		{propriedade.nome}
																	</label>
																</div>
															</Item>
														</Grid>
													))}
												</Grid>
											</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default InsumosCad;
