import { CropSeasonRepository } from "../repository/crop_season_repository";

export class CropSeasonService {

	constructor() {
		this.cropSeasonRepository = new CropSeasonRepository();
	}

	async get(propertyId, params) {
		const cropSeasonData = await this.cropSeasonRepository.get(propertyId, params);
		return cropSeasonData.cropsSeason;
	}

	async getById(id) {
		return await this.cropSeasonRepository.getById(id);
	}

	async getPlots(propertyId) {
		const plots = await this.cropSeasonRepository.getPlots(propertyId);
		return plots;
	}

	async save(cropSeason) {
		const savedCropSeason = await this.cropSeasonRepository.save(cropSeason);
		return savedCropSeason;
	}

	async update(cropSeason) {
		const savedCropSeason = await this.cropSeasonRepository.update(cropSeason);
		return savedCropSeason;
	}

	async delete(cropSeasonId) {
		await this.cropSeasonRepository.delete(cropSeasonId);
	}

	async finish(id, data) {
		const finishedCropSeason = await this.cropSeasonRepository.finish(id, data);
		return finishedCropSeason;
	}
}