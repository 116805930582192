import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";
import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { PropertyInputService } from "../../../../service/property_input_service";
import { PropertyService } from "../../../../service/property_service";
import { UserService } from "../../../../service/user_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
		marginVertical: "30px",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "16px",
		marginBottom: "3px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
		textTransform: "uppercase",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "20%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	larguraTextoMedida: {
		width: "20%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
		color: "red",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },

	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
});

const index = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [inputs, setInputs] = useState([]);
	const [company, setCompany] = useState(null);
	const [property, setProperty] = useState(null);
	const [loading, setLoading] = useState(true);
	const logo = props.logo;

	const propertyInputService = new PropertyInputService();
	const propertyService = new PropertyService();
	const userService = new UserService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const inputParams = {
			page: 1,
			limit: 1000,
		};
		const inputs = await propertyInputService.get(propertyId, inputParams);

		const currentProperty = await propertyService.getById(propertyId);
		setProperty(currentProperty);
		setCompany(currentProperty.empresa);

		const localUser = JSON.parse(localStorage.getItem("user"));
		const currentUser = await userService.getById(localUser.userId);
		setUser(currentUser);

		setInputs(inputs);
		setLoading(false);
	};
	
	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Lista de</Text>
							<Text style={styles.textTitleDocument}>INSUMOS</Text>
							<Text style={styles.textBoldFazenda}>
								{company?.nome_fantasia ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{property?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.body} key={index}>
						<View style={styles.mainTitle}>
							<Text>Produtos</Text>
						</View>
						<View key={index} style={styles.content}>
							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Produto</Text>
								<Text style={styles.larguraTexto}>Grupo</Text>
								<Text style={styles.larguraTexto}>Unid. Medida</Text>
								<Text style={styles.larguraTexto}>Interv. aplicação</Text>
								<Text style={styles.larguraTexto}>Carência</Text>
							</View>

							{inputs.map((propertyInput, indx) => {
								console.log(propertyInput);
								return (
									<View key={indx} style={styles.tableContent}>
										<Text style={styles.larguraTexto}>{propertyInput.insumoEmGrupo.insumo.nome}</Text>
										<Text style={styles.larguraTexto}>{propertyInput.insumoEmGrupo.grupoInsumo.nome}</Text>
										<Text style={styles.larguraTexto}>{propertyInput.unidadeMedida.sigla}</Text>
										<Text style={styles.larguraTexto}>{propertyInput.careanciaReaplicacaoDias}</Text>
										<Text style={styles.larguraTexto}>{propertyInput.carenciaColheitasDias}</Text>
									</View>
								); }
							)}
						</View>
					</View>

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};

export default index;

// const data = [
// 	{
// 		title: "Produtos",
// 		dataTable: [
// 			{
// 				grupo: "626565",
// 				produto: "alface",
// 				medida: "50",
// 				aplicacao: "50",
// 				carencia: "7 dias",
// 			},
// 			{
// 				grupo: "626546",
// 				produto: "melão",
// 				medida: "700",
// 				aplicacao: "700",
// 				carencia: "7 dias",
// 			},
// 			{
// 				grupo: "258625",
// 				produto: "melancia",
// 				medida: "1000",
// 				aplicacao: "1000",
// 				carencia: "7 dias",
// 			},
// 			{
// 				grupo: "216595",
// 				produto: "mamão",
// 				medida: "500",
// 				aplicacao: "500",
// 				carencia: "7 dias",
// 			},
// 		],
// 	},
// ];
