import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useMemo, useState } from "react";
import TableModal from "../../tableModalColheita";

//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import ButtonTable from "../../Buttons/buttonTable";
import styles from "./TableHarvestRequested.module.css";

import { TableSortLabel } from "@mui/material";
import dataModal from "../../../dataTests/dataColheitaModal.json";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	
	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const columnsModal = {
	numeroPedido: "N Pedido",
	cliente: "Cliente",
	espef: "Especificação",
	dataEntrega: "Data Entrega",
	qte: "Quantidade",
};
function HarvestRequested(props) {
	const [page, setPage] = useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = useState(7); //rows per page
	const keys =props.columns; //array de chaves
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(props.columns[0].id);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	
	// const [open, setOpen] = useState(false); //modal
	// const [dataControl, setDataControl] = useState({}); //data modal

	// const handleOpen = (e) => {
	// 	if (e.target.id !== undefined) setDataControl(e.target.id); //seta o id do elemento clicado para abrir o modal
	// 	setOpen(true); //abre o modal
	// };
	// const handleClose = () => {
	// 	setOpen(false); //fecha o modal
	// };

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const dataFilter = props.data.filter(
		(item) => item.cuture.toLowerCase().includes(props.search.toLowerCase())
		|| item.quant.toString().includes(props.search.toLowerCase()) 
		|| item.unid.toLowerCase().includes(props.search.toLowerCase()) 
		|| item.data_solicitacao.toString().includes(props.search.toLowerCase()) 
		|| item.data_realizacao.toString().includes(props.search.toLowerCase()) 
		|| item.operador.toLowerCase().includes(props.search.toLowerCase()) 
	);
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_solicitacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_realizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilter]
	);

	return (
		<div>
			<Paper>
				<TableContainer> 
					<Table stickyHeader>
						<EnhancedTableHead
							className={styles.tableGenerator}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClicks}
							onRequestSort={handleRequestSort}
							rowCount={dataFilter.length}
							columns={props.columns}
						/>
						<TableBody>
							{
								//cria as linhas da tabela
								visibleRows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.id}
												sx={{ height: 3 }}
											>
												{keys.map((column) => {
													//seta as colunas
													return (
														<TableCell
															id={row.id_cuture}
															// onClick={handleOpen}
															key={column.id}
														>
															{row[column.id]}
														</TableCell>
													);
												})}

												<TableCell>
													<ButtonTable
														className={styles.btn}
														editHref={`/colheita/cadastroColheita?id=${row.id}`}
														deletFunc={() => props.handleDelete(row.id)}
													/>
												</TableCell>
											</TableRow>
										);
									})
							}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
					component="div"
					colSpan={7}
					count={visibleRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Linhas por pagina"
					SelectProps={{
						inputProps: {
							"aria-label": "rows per page",
						},
						native: false,
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Paper>
			<Modal
				// open={open}
				// onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: "50%" }}>
					<div>
						<TableModal
							data={dataModal}
							// keys={dataControl}
							columns={columnsModal}
						/>
					</div>
				</Box>
			</Modal>
		</div>
	);
}

export default HarvestRequested;
