/* eslint-disable react/prop-types */
import React from "react";
//import material-ui
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

//import css
// import "./style.css";
import styles from "./tableModalColheita.module.css";
import { Box } from "@mui/material";

function TablePaginationActions(props) {
	const theme = useTheme(); //pega o tema do material-ui
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0); //primeira pagina
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1); //pagina anterior
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1); //proxima pagina
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)); //ultima pagina
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function TableModal(props) {
	const data = props.data; //pega os dados do componente pai
	const keys = Object.keys(props.columns); //pega as chaves do objeto
	const columns = Object.values(props.columns); //pega os valores do objeto
	const dataFilter = data.filter((item) => {
		//filtra os dados
		if (item.id_cuture.toString() === props.keys) return item;
	});

	const [page, setPage] = React.useState(0); //pagina atual
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //linhas por pagina

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta as linhas por pagina
		setPage(0);
	};

	return (
		<Paper sx={{ width: "100%" }}>
			<TableContainer sx={{ maxHeight: "75vh" }}>
				<Table stickyHeader>
					<TableHead className={styles.tableModal}>
						<TableRow className={styles.trModal}>
							{
								//cria as colunas
								columns.map((column) => (
									<TableCell className={styles.thModal} key={column.id}>
										{column}
									</TableCell>
								))
							}
							<TableCell className={styles.thModal}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							//cria as linhas
							dataFilter
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									return (
										/*gera linha */
										<TableRow
											className={styles.trModal}
											hover
											tabIndex={-1}
											key={row.id}
											sx={{ height: 3 }}
										>
											{keys.map((column) => {
												return (
													/* gera celula*/
													<TableCell className={styles.tdModal} key={column}>
														{row[column]}
													</TableCell>
												);
											})}
											{/*local do botao */}
											<TableCell className={styles.tdModal}>
												{props.children}
											</TableCell>
										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>
			</TableContainer>
			{/*paginação da tabela*/}
			<TablePagination
				className={styles.tablePagination}
				rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
				component="div"
				colSpan={7}
				count={dataFilter.length}
				rowsPerPage={rowsPerPage}
				page={page}
				labelRowsPerPage="Linhas por pagina"
				SelectProps={{
					inputProps: {
						"aria-label": "rows per page",
					},
					native: false,
				}}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</Paper>
	);
}

export default TableModal;
