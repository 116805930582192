/* eslint-disable react/prop-types */
import React from "react";
import * as I from "../IconsImport";

//import css modules
/* import "./style.css"; */
import styles from "./Buttons.module.css";

function Edit(props) {
	return (
		<a className={styles.editButton} href={props.href}>
			<I.IconEdit />
			<div className={styles.marginIconButton}>Editar</div>
		</a>
	);
}

function Delete(props) {
	return (
		<a className={styles.deleteButton} href={props.href}>
			<I.IconLosts />
			<div className={styles.marginIconButton}>Deletar</div>
		</a>
	);
}
function GenericButtonGreen(props) {
	if (props.href !== undefined && props.href !== null) {
		return (
			<a
				className={styles.genericButtonGreen}
				href={props.href}
				onClick={props.funcClick}
			>
				{props.icon}
				{props.text}
			</a>
		);
	} else {
		return (
			<button className={styles.genericButtonGreen} onClick={props.funcClick}>
				{props.icon}
				{props.text}
			</button>
		);
	}
}
function GenericButtonGray(props) {
	return (
		<a className={styles.genericButtonGray} href={props.href}>
			{props.icon}
			{props.text}
		</a>
	);
}

export { Edit, Delete, GenericButtonGreen, GenericButtonGray };
