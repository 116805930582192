import React, { useState } from "react";
// import "./style.css";
import styles from "./RegistrationArea.module.css";

//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Snackbar, Stack } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
};
function RegisterArea(props) {
	const dateAlteracao = new Date().toLocaleDateString("pt-BR");
	const horaAlteracao = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const userAlteracao = "";
	const dateCriacao = new Date().toLocaleDateString("pt-BR");
	const horaCriacao = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const userCriacao = "";
	const [open, setOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [textBottomSlv, setTextBottomSlv] = useState("Cadastrar");

	const handleClick = async () => {
		setDisabled(true);
		if (await props.salveFunc()) {
			setDisabled(true);
			setOpen(true);
			setTextBottomSlv("Cadastrado");
			setTimeout(function () {
				window.location.href = props.href;
				setTextBottomSlv("Cadastrar");
			}, 1500);
		} else {
			setDisabled(false);
			return setOpen(true);
		}
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") return;

		setOpen(false);
	};
	return (
		<>
			<Box sx={{ width: "100%", px: { xs: 0, sm: 1, md: 2 } }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 1, md: 2 }}>
					<Grid item xs={12} sm={12} md={12}>
						<Item sx={stylesItem}>
							<a className={styles.headerRegisterAreaButton} href={props.href}>
								<ArrowBackIcon />
							</a>
							<h1 className={styles.headerRegisterAreaTitle}>{props.title}</h1>
						</Item>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<Item sx={stylesItem}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Grid item xs={12} sm={12} md={12}>
									<Item sx={stylesItem}>
										<p className={styles.registrationTitle}>
											Preencha os dados abaixo
										</p>
									</Item>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<form>
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationInput}>
														{props.children}
													</div>
												</Item>
											</Grid>

											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<Divider />
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 0, sm: 0, md: 0 }}
													>
														<Grid item xs={12} sm={6} md={6}>
															<Item sx={stylesItem}>
																<Grid
																	container
																	rowSpacing={1}
																	columnSpacing={{ xs: 0, sm: 0, md: 0 }}
																	sx={{
																		display: "flex",
																		flexDirection: {
																			xs: "column",
																			sm: "row",
																		},
																	}}
																>
																	{props.dataCriacao === true ? (
																		<Grid item xs={12} sm={6} md={6}>
																			<Item
																				sx={{
																					bgcolor: "rgba(0, 0, 0, 0)",
																					border: "none",
																					boxShadow: "none",
																					display: "flex",
																					alignItems: "start",
																					justifyContent: "start",
																					flexDirection: "column",
																				}}
																			>
																				<p className={styles.dataTItle}>
																					Data de criação
																				</p>
																				<p>
																					{dateCriacao +
																						" " +
																						horaCriacao +
																						" " +
																						userCriacao}
																				</p>
																			</Item>
																		</Grid>
																	) : (
																		""
																	)}
																	{props.dataAlteracao === true ? (
																		<Grid item xs={12} sm={6} md={6}>
																			<Item
																				sx={{
																					bgcolor: "rgba(0, 0, 0, 0)",
																					border: "none",
																					boxShadow: "none",
																					display: "flex",
																					alignItems: "start",
																					justifyContent: "start",
																					flexDirection: "column",
																				}}
																			>
																				<p className={styles.dataTItle}>
																					Data de alteração
																				</p>
																				<p>
																					{dateAlteracao +
																						" " +
																						horaAlteracao +
																						" " +
																						userAlteracao}
																				</p>
																			</Item>
																		</Grid>
																	) : (
																		""
																	)}
																</Grid>
															</Item>
														</Grid>

														<Grid item xs={12} sm={6} md={6}>
															<Item sx={stylesItem}>
																<Grid
																	container
																	rowSpacing={1}
																	columnSpacing={{ xs: 0, sm: 0, md: 0 }}
																	sx={{
																		display: "flex",
																		flexDirection: {
																			xs: "column-reverse",
																			sm: "row",
																		},
																	}}
																>
																	<Grid item xs={12} sm={6} md={6}>
																		<Item sx={stylesItem}></Item>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6}>
																		<Item sx={stylesItem}>
																			<button
																				type="button"
																				onClick={handleClick}
																				disabled={disabled}
																				className={
																					disabled
																						? styles.registrationButtonCancelar
																						: styles.registrationButtoncadastrar
																				}
																			>
																				{textBottomSlv}
																			</button>
																		</Item>
																	</Grid>
																</Grid>
															</Item>
														</Grid>
													</Grid>
												</Item>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Item>
					</Grid>
				</Grid>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
						<Alert
							onClose={handleClose}
							severity={props.typeMessage}
							sx={{ width: "100%" }}
						>
							{props.message}
						</Alert>
					</Snackbar>
				</Stack>
			</Box>
		</>
	);
}
export default RegisterArea;
