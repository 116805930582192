import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { UserService } from "../../service/user_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column

function Users() {
	const userService = new UserService();
	const [search, setSearch] = useState("");
	const [users, setUsers] = useState([]);
	const [columns, setColumns] = useState([
		{
			id: "nome",
			label: "Nome",
		},
		{
			id: "email",
			label: "E-mail",
		},
		{
			id: "telefone",
			label: "Telefone",
		},
		{
			id: "permissao_exclusao_texto",
			label: "Exclusão de dados",
		},
		{
			id: "ativo_texto",
			label: "Ativo",
		},

	]);

	useEffect(() => {
		getAndSetUsuarios();
	}, []);

	const getAndSetUsuarios = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user.isAdmin) {
			const usersInCompany = await getUsersInCompany(user.businessId);
			setUsers(usersInCompany);
			setColumns([
				{
					id: "nome",
					label: "Nome",
				},
				{
					id: "email",
					label: "E-mail",
				},
				{
					id: "telefone",
					label: "Telefone",
				},
				{
					id: "permissao_exclusao_texto",
					label: "Exclusão de dados",
				},
				{
					id: "ativo_texto",
					label: "Ativo",
				},
				{
					id: "admin_texto",
					label: "Admin",
				},
			]);
		} else {
			const usersInProperty = await getUsersInProperty();
			setUsers(usersInProperty);
			setColumns([
				{
					id: "nome",
					label: "Nome",
				},
				{
					id: "email",
					label: "E-mail",
				},
				{
					id: "telefone",
					label: "Telefone",
				},
				{
					id: "permissao_exclusao_texto",
					label: "Exclusão de dados",
				},
				{
					id: "ativo_texto",
					label: "Ativo",
				},
			]);
		}
	};

	const getUsersInProperty = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const propertyUsers = await userService.getByProperty(propertyId);

		const usersInProperty = [];
		for (let i = 0; i < propertyUsers.length; i++) {
			const user = propertyUsers[i].usuario;

			user.email = user.email ?? "";
			user.telefone = user.telefone ?? "";
			user.permissao_exclusao_texto = user.permissao_exclusao ? "Sim" : "Não";
			user.ativo_texto = user.ativo ? "Sim" : "Não";

			usersInProperty.push(user);
		}

		return usersInProperty;
	};

	const getUsersInCompany = async (businessId) => {
		const usersInCompany = [];
		const companyUsers = await userService.getByBusiness(businessId);
		for (let i = 0; i < companyUsers.length; i++) {
			const companyUser = companyUsers[i];

			companyUser.email = companyUser.email ?? "";
			companyUser.telefone = companyUser.telefone ?? "";
			companyUser.permissao_exclusao_texto = companyUser.permissao_exclusao ? "Sim" : "Não";
			companyUser.ativo_texto = companyUser.ativo ? "Sim" : "Não";
			companyUser.admin_texto = companyUser.admin ? "Sim" : "Não";

			usersInCompany.push(companyUser);
		}
		return usersInCompany;
	};

	const dataFilter =
		users.length > 0
			? users.filter(
				(item) =>
					JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
			)
			: [];

	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		const index = users.findIndex((user) => user.id === id);
		const user = users[index];

		const userToUpdate = {
			id: user.id,
			nome: user.nome,
			login: user.login,
			password: user.password,
			email: user.email,
			telefone: user.telefone,
			operador: user.operador,
			admin: user.admin,
			ativo: !user.ativo,
			permissao_propriedade: user.permissao_propriedade,
			permissao_usuario: user.permissao_usuario,
			permissao_cultura: user.permissao_cultura,
			permissao_exclusao: user.permissao_exclusao,
			permissao_logo: user.permissao_logo,
			permissao_cliente: user.permissao_cliente,
			permissao_servico: user.permissao_servico,
			permissao_colheita: user.permissao_colheita,
			permissao_relatorio: user.permissao_relatorio,
			permissao_estoque: user.permissao_estoque,
			permissao_ocorrencia: user.permissao_ocorrencia,
			permissao_perda: user.permissao_perda,
			permissao_pedido: user.permissao_pedido,
			permissao_manejo: user.permissao_manejo,
			permissao_safra: user.permissao_safra,
			permissao_insumo: user.permissao_insumo,
			permissao_precificacao: user.permissao_precificacao,
			empresaId: user.empresaId,
			includeProperties: [],
			removeProperties: []
		};
		
		await userService.update(userToUpdate);
		await getAndSetUsuarios();
	};

	return (
		<div>
			<TopMenu open={["register", "users"]} help="user" />

			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Usuários"
									href="/usuarios/cadastroUsuario"
									textButton="Novo Usuário"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									optionSettings={"user"}
									handleDelete={handleDelete}
									editHref="usuarios/cadastroUsuario?id="
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Users;
