// import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Box, Grid, Modal, Snackbar, Stack } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import InputMask from "../../../../components/InputMask";
// import { Usuario } from "../../../../models";
import styles from "../../SettingsPag.module.css";
// import { data } from "../Evaluation/dataEvaluation";
import HttpsIcon from "@mui/icons-material/Https";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { UserService } from "../../../../service/user_service";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};

function SecurityPrivacy() {
	// const dataEvaluation = data;
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [login, setLogin] = useState("");
	const [senhaAtual, setSenhaAtual] = useState("");
	const [senhaNova, setSenhaNova] = useState("");
	const [senhaConfirmacao, setSenhaConfirmacao] = useState("");
	const [showPassword, setShowPassword] = useState(true);
	const [showPasswordCurrent, setShowPasswordCurrent] = useState(true);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
	const [openLogin, setOpenLogin] = useState(false);
	const [openEmail, setOpenEmail] = useState(false);
	const [openPhone, setOpenPhone] = useState(false);
	const [openSenha, setOpenSenha] = useState(false);
	const [aux, setAux] = useState("");
	const [openAlertModal, setOpenAlertModal] = useState(false);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");

	const userService = new UserService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const localUser = JSON.parse(localStorage.getItem("user"));
		const user = await userService.getById(localUser.userId);
		setLogin(user.login);
		setEmail(user.email);
		setPhone(user.telefone?.replace("+55", ""));
	};

	function handleClick() {
		setShowPassword(!showPassword);
		if (showPassword) document.getElementById("password").type = "text";
		else document.getElementById("password").type = "password";
	}
	function handleClickCurrent() {
		setShowPasswordCurrent(!showPasswordCurrent);
		if (showPasswordCurrent)
			document.getElementById("oldPassword").type = "text";
		else document.getElementById("oldPassword").type = "password";
	}
	function handleClickConfirm() {
		setShowPasswordConfirm(!showPasswordConfirm);
		if (showPasswordConfirm)
			document.getElementById("passwordConfirm").type = "text";
		else document.getElementById("passwordConfirm").type = "password";
	}

	const handleOpenSenha = () => {
		setAux(senhaAtual);
		setOpenSenha(true); //abre o modal
	};
	const handleOpenEmail = () => {
		setAux(email);
		setOpenEmail(true); //abre o modal
	};
	const handleOpenPhone = () => {
		setAux(phone);
		setOpenPhone(true); //abre o modal
	};
	const handleOpenLogin = () => {
		setAux(login);
		setOpenLogin(true); //abre o modal
	};
	const handleClose = () => {
		setOpenSenha(false); //fecha o modal
		setOpenPhone(false); //fecha o modal
		setOpenEmail(false); //fecha o modal
		setOpenLogin(false);
	};

	const handleCloseCancelSenha = () => {
		setSenhaAtual(aux);
		setOpenSenha(false); //fecha o modal
	};
	const handleCloseCancelPhone = () => {
		setPhone(aux);
		setOpenPhone(false); //fecha o modal
	};
	const handleCloseCancelEmail = () => {
		setEmail(aux);
		setOpenEmail(false); //fecha o modal
	};
	const handleCloseCancelLogin = () => {
		setLogin(aux);
		setOpenLogin(false); //fecha o modal
	};

	const handleClosePhone = async () => {
		await handleSave();
		handleClose();
	};

	const handleCloseEmail = async () => {
		await handleSave();
		handleClose();
	};

	const handleCloseLogin = async () => {
		await handleSave();
		handleClose();
	};

	const handleSave = async () => {
		try {
			const localUser = JSON.parse(localStorage.getItem("user"));
			const user = await userService.getById(localUser.userId);

			let dataToUpdate = {
				id: user.id,
				nome: user.nome,
				login: user.login,
				email: user.email,
				telefone: user.telefone,
				operador: user.operador,
				admin: user.admin,
				ativo: user.ativo,
				permissao_propriedade: user.permissao_propriedade,
				permissao_usuario: user.permissao_usuario,
				permissao_cultura: user.permissao_cultura,
				permissao_exclusao: user.permissao_exclusao,
				permissao_logo: user.permissao_logo,
				permissao_cliente: user.permissao_cliente,
				permissao_servico: user.permissao_servico,
				permissao_colheita: user.permissao_colheita,
				permissao_relatorio: user.permissao_relatorio,
				permissao_estoque: user.permissao_estoque,
				permissao_ocorrencia: user.permissao_ocorrencia,
				permissao_perda: user.permissao_perda,
				permissao_pedido: user.permissao_pedido,
				permissao_manejo: user.permissao_manejo,
				permissao_safra: user.permissao_safra,
				permissao_insumo: user.permissao_insumo,
				permissao_precificacao: user.permissao_precificacao,
				includeProperties: [],
				removeProperties: []
			};

			if(email && email !== "")
				dataToUpdate.email = email;

			if(phone && phone !== "")
				dataToUpdate.telefone = `+55${phone}`;

			if(login && login !== "")
				dataToUpdate.login = login;

			await userService.update(dataToUpdate);
			initData();
		} catch (err) {
			console.log(err);
		}
	};

	const handleCloseBtnAddSenha = async () => {
		if (senhaAtual !== "") {
			if (
				senhaNova === senhaConfirmacao &&
				senhaNova !== senhaAtual &&
				senhaAtual !== "" &&
				senhaConfirmacao !== ""
			) {
				try {
					await userService.updatePassword({
						newPassword: senhaNova,
						oldPassword: senhaAtual
					});
					handleClose();
					setSenhaAtual("");
					setSenhaConfirmacao("");
					setSenhaNova("");
				} catch (err) {
					let message = "nova senha Incorreta/igual a senha antiga";
					setMessage(message);
					setTypeMessage("error");
					setOpenAlertModal(true);
				}
			} else {
				let message = "nova senha Incorreta/igual a senha antiga";
				setMessage(message);
				setTypeMessage("error");
				setOpenAlertModal(true);
			}
		} else {
			let message = "Senha atual Incorreta";
			setMessage(message);
			setTypeMessage("error");
			setOpenAlertModal(true);
		}
	};

	const handleCloseAlertModal = (event, reason) => {
		if (reason === "clickaway") return;

		setOpenAlertModal(false);
	};

	function formatPhone(phone) {
		let test = phone.split("");
		if (test.length === 0) {
			return "Nenhum telefone cadastrado";
		} else {
			return (
				"(" +
				test[0] +
				test[1] +
				") " +
				test[2] +
				test[3] +
				test[4] +
				test[5] +
				test[6] +
				" - " +
				test[7] +
				test[8] +
				test[9] +
				test[10]
			);
		}
	}

	return (
		<>
			<Box component="main">
				<Box
					sx={{
						width: "100%",
						minWidth: { xs: "100vw", sm: "66.666667vw", md: "75vw" },
						height: "100%",
						minHeight: "90vh",
						bgcolor: "white",
						m: 0,
						mt: 2,
						borderRadius: 8,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={0}
						sx={{
							display: "flex",
							width: "100%",
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							p: 3,
							m: 0,
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								width: "100%",
							}}
						>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid item xs={12} sm={6} md={6}>
									<p>Login</p>
									<h4>{login}</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenLogin}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Email</p>
									<h4>{email}</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenEmail}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Telefone</p>
									<h4>{formatPhone(phone)}</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenPhone}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<p>Senha</p>
									<h4>**********</h4>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<button
										onClick={handleOpenSenha}
										className={styles.buttonConfig}
									>
										editar
									</button>
								</Grid>
							</Grid>
							{/* <Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									width: "100%",
									textAlign: "center",
									flexDirection: "column",
									mt: 5,
								}}
							>
								<h4>
									<b>Desconectar de aparelhos</b>
								</h4>
								<List
									sx={{
										width: "100%",
										overflowY: "auto",
										height: "100%",
										maxHeight: "300px",
									}}
								>
									{dataEvaluation.map((item, index) => {
										// <ListItem alignItems="flex-start" key={index}>
										// 	<ListItemText primary={item.nome} />
										// </ListItem>
										return (
											<>
												<ListItem
													key={index}
													secondaryAction={
														<IconButton
															edge="end"
															aria-label="delete"
															onClick={() => console.log(item.id)}
														>
															<DeleteIcon />
														</IconButton>
													}
												>
													<ListItemText
														primary={item.nome}
														// secondary={secondary ? "Secondary text" : null}
													/>
												</ListItem>
											</>
										);
									})}
								</List>
							</Grid> */}
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openLogin}
				className={styles.registrationInputModal}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Login</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<input
								type="text"
								value={login}
								onChange={(e) => setLogin(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelLogin}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseLogin}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openEmail}
				className={styles.registrationInputModal}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Email</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelEmail}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseEmail}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openPhone}
				className={styles.registrationInputModal}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Telefone</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<InputMask
								value={phone}
								mask="(99)99999-9999"
								onChange={(e) => setPhone(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelPhone}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleClosePhone}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openSenha}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className={styles.registrationInputModal}
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Senha Atual</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12} className={styles.formInput}>
							<label htmlFor="oldPassword" className={styles.icon}>
								<HttpsIcon />
							</label>
							<input
								type="password"
								id="oldPassword"
								className={styles.input}
								placeholder={"Senha Atual"}
								value={senhaAtual}
								onChange={(e) => setSenhaAtual(e.target.value)}
							/>
							<div onClick={handleClickCurrent}>
								{showPasswordCurrent ? (
									<VisibilityIcon className={styles.iconPassword} />
								) : (
									<VisibilityOffIcon className={styles.iconPassword} />
								)}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<p>Nova Senha</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12} className={styles.formInput}>
							<label htmlFor="password" className={styles.icon}>
								<HttpsIcon />
							</label>
							<input
								type="password"
								id="password"
								className={styles.input}
								placeholder={"Nova Senha"}
								value={senhaNova}
								onChange={(e) => setSenhaNova(e.target.value)}
							/>
							<div onClick={handleClick}>
								{showPassword ? (
									<VisibilityIcon className={styles.iconPassword} />
								) : (
									<VisibilityOffIcon className={styles.iconPassword} />
								)}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<p>Confirmar nova Senha</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12} className={styles.formInput}>
							<label htmlFor="passwordConfirm" className={styles.icon}>
								<HttpsIcon />
							</label>
							<input
								type="password"
								id="passwordConfirm"
								className={styles.input}
								placeholder={"Confirmar nova Senha"}
								value={senhaConfirmacao}
								onChange={(e) => setSenhaConfirmacao(e.target.value)}
							/>
							<div onClick={handleClickConfirm}>
								{showPasswordConfirm ? (
									<VisibilityIcon className={styles.iconPassword} />
								) : (
									<VisibilityOffIcon className={styles.iconPassword} />
								)}
							</div>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelSenha}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseBtnAddSenha}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={openAlertModal}
					autoHideDuration={6000}
					onClose={handleCloseAlertModal}
				>
					<Alert
						onClose={handleCloseAlertModal}
						severity={typeMessage}
						sx={{ width: "100%" }}
					>
						{message}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	);
}

export default SecurityPrivacy;
