/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Search from "../../components/SearchHeader";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";

//import css modules
import styles from "./Stock.module.css";

import * as Btn from "../../components/Buttons";
import * as I from "../../components/IconsImport";

//tabs table imports
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, Menu, MenuItem, Modal, TableSortLabel, ThemeProvider, createTheme, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as locales from "@mui/material/locale";
import { visuallyHidden } from "@mui/utils";
import { BusinessService } from "../../service/business_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	p: 0,
	m: 0,
};

//inicio data insumos
const columnsInsumos = [
	{
		id: "insumo_nome",
		label:"Nome",
	},
	{
		id: "unidademedida",
		label: "Unidade",
	},
	{
		id: "entrada",
		label: "Entrada",
	},
	{
		id: "saida",
		label: "Saída",
	},
	{
		id: "saldo",
		label:"Saldo",
	},
];

//inicio data produtos
const columns = [
	{
		id: "produto",
		label:"Produto",
	},
	{
		id: "DataEntrada",
		label: "Data de entrada",
	},
	{
		id: "quantEntrada",
		label:"quantidade de entrada",
	},
	{
		id: "quantSaida",
		label:"Quantidade de saida",
	},
	{
		id: "perda",
		label:"Perda",
	},
	{
		id: "saldo",
		label:"Saldo",
	},
	{
		id: "lote",
		label:"Lote",
	},
	{
		id: "safras",
		label:"Safra",
	},
]; 

import { Link } from "react-router-dom";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import { StockService } from "../../service/stock_service";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
function Stock() {
	const [search, setSearch] = useState("");
	const [value, setValue] = React.useState(0);
	const [insumoStock, setInsumoStock] = useState([]);
	const [productsStock, setProductsStock] = useState([]);
	const [page, setPage] = React.useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //rows per page
	const [id, setId] = useState("");
	const [open, setOpen] = useState(false); //dialog
	const [openModal, setOpenModal] = useState(false);
	const [colheitasRealizada, setColheitasRealizada] = React.useState([]);
	const [columnsHarvestHarvested, setColumnsHarvestHarvested] = useState({});
	const [loteId, setLoteid] = useState("");
	const [disableGerenciarInsumos, setDisableGerenciarInsumos] = useState(true);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [selected, setSelected] = React.useState([]);

	const businessService = new BusinessService();
	const stockService = new StockService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleSelectAllClickColhido = (event) => {
		if (event.target.checked) {
			const newSelected = datafiltroColhido.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleCloseModal = () => {
		setOpenModal(false); //fecha o modal
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};
	const handleOpen = (e) => {
		if (e.target.id !== undefined) setId(e.target.id); //seta o id do elemento clicado para abrir o dialog

		setOpen(true); //abre o modal
	};

	const handleClose = () => {
		setOpen(false); //fecha o modal
	};

	useEffect(() => {
		initData();
	}, []);
	
	const initData = async () => {
		const generalParams = {
			page: 1,
			limit: 1000,
		};
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");
		const business = await businessService.getById(user.businessId);
		const licensa = business.licensa;
		if(licensa.gerencia_insumos)setDisableGerenciarInsumos(false);
		
		const inputsInProperty = await stockService.getInputs(propertyId);
		setInsumoStock(getStockForInputs(inputsInProperty));

		const productsInProperty = await stockService.getProducts(propertyId);
		setProductsStock(getProductsInProperty(productsInProperty));
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const getStockForInputs = (stocksForInputs) => {
		const inputsInfo = [];
		stocksForInputs.forEach((stock) => {
			if (stock.entra === 0 && stock.saida === 0) return;
			const stockAux = {
				id: stock.id,
				unidadeID: stock.unidadeMedidaId,
				unidademedida: stock.unidadeMedidaSigla,
				insumo_nome: stock.nome,
				insumo_id: stock.insumoId,
				entrada: stock.entra,
				saida: stock.saida,
				saldo: stock.saldo
			};
			inputsInfo.push(stockAux);
		});
		return inputsInfo;
	};

	const getProductsInProperty = (productsInProperty) => {
		const productsInfo = [];
		productsInProperty.forEach((product) => {
			if (product.entrada === 0 && product.saida === 0 && product.perda === 0) return;
			const productAux = {
				id: product.loteId,
				itemId: product.loteId,
				quantEntrada: product.entrada,
				quantSaida: product.saida,
				produto: captalizeFirstLetterEacheWord(`${product.cultura} ${(product.variedade ?? "")}`),
				saldo: product.saldo,
				DataEntrada: convertDateToString(product.DataEntrada),
				lote: product.loteCodigo,
				safras: product.safras,
				perda: product.perda,
			};
			productsInfo.push(productAux);
		});
		return productsInfo;
	};

	const convertDateToString = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	//função pesquisa
	const dataFilter = productsStock.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	const handleOpenModal = (e) => {
		setLoteid(e.target.id);
		setOpenModal(true); //abre o modal
	};
	const datafiltroColhido = colheitasRealizada.filter((item) =>{
		return JSON.stringify(item.id).toLowerCase().includes(loteId);
	});

	function handleClick(e) {
		setSearch(e.target.value);
	}
	
	const theme = useTheme();
	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const styleBtn = { color: "#00A69A", margin: 0 };
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [clienteN, setClienteN] = useState("");
	const openBtn = Boolean(anchorEl);
	const handleClickBtn = (event, row, clienteName) => {
		setId(row);
		setClienteN(clienteName);
		setAnchorEl(event.currentTarget);
	};
	const handleCloseBtn = () => {
		setAnchorEl(null);
	};

	const getFormatDate = (date) => {
		if (date !== undefined) {
			let data = date.split("/");
			return `${data[2]}-${data[1]}-${data[0]}`;
		}
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "DataEntrada") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_realizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilter]
	);
	const visibleRowsColhido = useMemo(
		() =>
			stableSort(datafiltroColhido, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,datafiltroColhido]
	);
	const visibleRowsInsumo = useMemo(
		() =>
			stableSort(insumoStock, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage,insumoStock]
	);

	return (
		<div>
			<TopMenu open={["Query", "stock"]} help="stock" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search title="Estoque">
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Grid
									container
									rowSpacing={0}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item sx={stylesItem}>
											<Tabs
												value={value}
												onChange={handleChange}
												textColor="white"
												indicatorColor="primary"
												className={disableGerenciarInsumos ? styles.tabDesabilitado : styles.tabs }
											>
											
												<Tab
													className={value === 0 ? styles.focuz : styles.tab}
													label="Produtos"
													{...a11yProps(0)}
												/>
												<Tab
													className={ disableGerenciarInsumos ? styles.tabDesabilitado : (value === 1 ? styles.focuz : styles.tab) }
													label="Insumos"
													{...a11yProps(1)}
													disabled={disableGerenciarInsumos}
												/>
											</Tabs>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Item sx={stylesItem}>
											<TabPanel
												className={styles.tabPanel}
												value={value}
												index={1}
											>
												<Paper sx={{ width: "100%" }}>
													<ThemeProvider theme={themeWithLocale}>
														<Grid item xs={12} sm={12} md={12}>
															<Item sx={stylesItem}>
																<Btn.GenericButtonGreen
																	href="/estoque/cadastroEstoque"
																	text="Cadastrar entrada de insumo"
																	icon={
																		<div>
																			<I.IconAdd />
																		</div>
																	}
																/>
															</Item>
														</Grid>
														<TableContainer sx={{ maxHeight: "75vh" }}>
															<Table stickyHeader>
																<EnhancedTableHead
																	numSelected={selected.length}
																	order={order}
																	orderBy={orderBy}
																	onSelectAllClick={handleSelectAllClicks}
																	onRequestSort={handleRequestSort}
																	rowCount={insumoStock.length}
																	columns={columnsInsumos}
																/>
																<TableBody>
																	{
																		//cria as linhas da tabela
																		visibleRowsInsumo
																			.slice(
																				page * rowsPerPage,
																				page * rowsPerPage + rowsPerPage
																			)
																			.map((row) => {
																				return (
																					<TableRow
																						hover
																						tabIndex={-1}
																						key={row.id}
																						sx={{ height: 3 }}
																						className={styles.lineRequest}
																					>
																						{columnsInsumos.map(
																							(column) => {
																								return (
																									<TableCell
																										id={row.id}
																										onClick={handleOpen}
																										key={column}
																									>
																										{row[column.id]}
																									</TableCell>
																								);
																							}
																						)}
																						<TableCell>
																							<div className={styles.btn}>
																								<Button
																									aria-controls={openBtn ? "basic-menu" : undefined}
																									aria-haspopup="true"
																									aria-expanded={openBtn ? "true" : undefined}
																									id={row.orderNumber}
																									onClick={(e) => handleClickBtn(e, row.id, row.orderNumber)}
																								>
																									<I.IconMoreVert style={styleBtn} />
																								</Button>
																								<Menu
																									id="basic-menu"
																									anchorEl={anchorEl}
																									open={openBtn}
																									onClose={handleCloseBtn}
																									MenuListProps={{
																										"aria-labelledby": "basic-button",
																									}}
																								>																									
																									<MenuItem id={row.id+"&"+row.orderNumber}  	onClick={() => window.location = `/estoque/detalhes/${id}` }>
																										detalhes do insumo
																									</MenuItem>
																								</Menu>
																							</div>
																						</TableCell>
																					</TableRow>
																				);
																			})
																	}
																</TableBody>
															</Table>
														</TableContainer>
														<TablePagination
															rowsPerPageOptions={[
																7,
																14,
																21,
																{ label: "Todos", value: Number.MAX_VALUE },
															]}
															component="div"
															colSpan={7}
															count={insumoStock.length}
															rowsPerPage={rowsPerPage}
															page={page}
															labelRowsPerPage="Linhas por pagina"
															SelectProps={{
																inputProps: {
																	"aria-label": "rows per page",
																},
																native: false,
															}}
															onPageChange={handleChangePage}
															onRowsPerPageChange={handleChangeRowsPerPage}
															ActionsComponent={TablePaginationActions}
														/>
													</ThemeProvider>
												</Paper>
											</TabPanel>
											<TabPanel
												className={styles.tabPanel}
												value={value}
												index={0}
											>
												<Paper sx={{ width: "100%" }}>
													<ThemeProvider theme={themeWithLocale}>
														<TableContainer sx={{ maxHeight: "75vh" }}>
															<Table stickyHeader>
																
																<EnhancedTableHead
																	numSelected={selected.length}
																	order={order}
																	orderBy={orderBy}
																	onSelectAllClick={handleSelectAllClicks}
																	onRequestSort={handleRequestSort}
																	rowCount={dataFilter.length}
																	columns={columns}
																/>
																<TableBody>
																	{
																		//cria as linhas da tabela
																		visibleRows
																			.slice(
																				page * rowsPerPage,
																				page * rowsPerPage + rowsPerPage
																			)
																			.map((row) => {
																				return (
																					<TableRow
																						hover
																						tabIndex={-1}
																						key={row.id}
																						sx={{ height: 3 }}
																						className={styles.lineRequest}
																					>
																						{columns.map(
																							(column) => {
																								//seta as colunas
																								return (
																									<TableCell
																										id={row.itemId}
																										onClick={handleOpenModal}
																										key={column}
																									>
																										{row[column.id]}
																									</TableCell>
																								);
																							}
																						)}
																						
																					</TableRow>
																				);
																			})
																	}
																</TableBody>
															</Table>
														</TableContainer>
														<TablePagination
															rowsPerPageOptions={[
																7,
																14,
																21,
																{ label: "Todos", value: Number.MAX_VALUE },
															]}
															component="div"
															colSpan={7}
															count={dataFilter.length}
															rowsPerPage={rowsPerPage}
															page={page}
															labelRowsPerPage="Linhas por pagina"
															SelectProps={{
																inputProps: {
																	"aria-label": "rows per page",
																},
																native: false,
															}}
															onPageChange={handleChangePage}
															onRowsPerPageChange={handleChangeRowsPerPage}
															ActionsComponent={TablePaginationActions}
														/>
													</ThemeProvider>
												</Paper>
											</TabPanel>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Deseja viualiazar mais detalhes do item? {id} {clienteN}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
					<button className={styles.ButtonCancelarModal} onClick={handleClose}>
						Cancelar
					</button>

					<Link to={`/estoque/detalhes/${id}`}>
						<button
							className={styles.ButtonColherModal}
							// onClick={console.log(insumoStock)}
						>
							ver
						</button>
					</Link>
				</DialogActions>
			</Dialog>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Produtos</b>
								</h2>
							</div>
							<Paper sx={{ width: "100%", p: 0, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer>
										<Table stickyHeader>
											<EnhancedTableHead
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onSelectAllClick={handleSelectAllClickColhido}
												onRequestSort={handleRequestSort}
												rowCount={datafiltroColhido.length}
												columns={columnsHarvestHarvested}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRowsColhido
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{columnsHarvestHarvested.map(
																		(column) => {
																			return (
																				<TableCell
																					key={column}
																					id={row.id}
																					onClick={handleOpenModal}
																				>
																					{row[column.id]}
																				</TableCell>
																			);
																		}
																	)}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[
											7,
											14,
											21,
											{ label: "Todos", value: Number.MAX_VALUE },
										]}
										component="div"
										colSpan={7}
										count={datafiltroColhido.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default Stock;
