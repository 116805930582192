/* eslint-disable no-unused-vars */
import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { CropSeasonService } from "../../../../service/crop_season_service";
import { BusinessService } from "../../../../service/business_service";
import { PropertyService } from "../../../../service/property_service";
import { ReportService } from "../../../../service/report_service";
import { UserService } from "../../../../service/user_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
		fontFamily: "Roboto bold",
	},

	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "2px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginVertical: "10px",
		fontFamily: "Roboto bold",
	},
	Subtitle: {
		backgroundColor: "#E0DFDF",
		padding: "2px 8px",
		textTransform: "uppercase",
		color: "#03989E",
		borderRadius: "8px",
		letterSpacing: "1px",
		marginVertical: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		fontSize: "13px",
		fontFamily: "Roboto bold",
	},
	Body: {
		paddingBottom: "15px",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "1px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
		fontWeight: "hairline",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "40%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
		fontFamily: "Roboto bold",
	},
	containerNumberTotal: {
		width: "85%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

let totalValuesInput = {};
let totalValuesService = {};

function PdfCadernoCampo(props) {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-BR", "h:mm A");
	const [user, setUser] = useState(null);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	const logo = props.logo;
	const custo = props.custo;

	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);
	
	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const params = {
			cropSeason: String(props.safraId) !== "-1" ? props.safraId : null,
			propertyId: propertyId,
			startDate: props.startDate,
			finalDate: props.finalDate,
			withValues: true,
		};

		const notebookFieldData = await reportService.getFieldNotebookData(params);
		setData(notebookFieldData);
		setLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const formatTime = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getHours()).slice(-2) +
			":" +
			("0" + date.getMinutes()).slice(-2)
		);
	};

	function convertToHours(number) {
		const hours = Math.floor(number / 60);
		const minutes = number % 60;

		let formattedHours = "";
		if (hours < 100)
			formattedHours = `0${hours}`;
		else
			formattedHours = hours.toString();
		
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		
		return `${formattedHours}:${formattedMinutes}`;
	}
	
	function geraDocumento() {
		return (
			!loading ?
				<Document>
					<Page size="A4" style={styles.page} wrap>
						<View style={styles.header} fixed>
							<View>
								<Text style={styles.textTitleDocument}>Caderno de Campo</Text>
								<Text style={styles.textBoldFazenda}>
									{data.empresa?.nome ?? "Não informado"}
								</Text>
								<Text style={styles.textBoldPropriedade}>
									{data.propriedade?.nome ?? "Não informado"}
								</Text>
							</View>
							{logo !== null &&
							<Image style={styles.logo} src={logo} />}
						</View>
						<View style={styles.line} fixed></View> {/* linaha cinza*/}
						<View style={styles.containerSubHeader}>
							<View>
								<Text style={styles.textBold}>Safra:</Text>
								<Text style={styles.textNormal}>{data.safra?.nome ?? "Todas as safras"}</Text>
								<Text style={styles.textNormal}>{data.area?.nome ?? "Todas as áreas"}</Text>
								<Text style={styles.textBold}>Período: </Text>
								<Text style={styles.textNormal}>
									{formatDate(props.startDate)} a {formatDate(props.finalDate)}
								</Text>
							</View>

							{custo && (
								<View style={styles.containerTotal}>
									<Text style={styles.textBold}>total:</Text>
									<Text style={styles.textNormal}>
										{data.total.toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})}
									</Text>
								</View>
							)}
						</View>
						<View style={styles.Body}>
							<View style={styles.mainTitle}>
								<Text>Produtos Aplicados</Text>
							</View>
							{Object.keys(data.produtos).map((key, index) => {
								return (
									<View key={index}>
										<View style={styles.Subtitle}>
											<Text>{key}</Text>
											{/* {custo && <Text>TOTAL:5000</Text>} */}
										</View>
										<View style={styles.content}>
											<View style={styles.tableTitle}>
												<Text style={styles.larguraTexto}>data:</Text>
												<Text style={styles.larguraTexto}>Produto:</Text>
												<Text style={styles.larguraTexto}>quant.</Text>
												<Text style={styles.larguraTexto}>unidade:</Text>
												<Text style={styles.larguraTexto}>volume da calda:</Text>
												<Text style={styles.larguraTexto}>modo:</Text>
												{custo && <Text style={styles.larguraTexto}>Vlr Unt:</Text>}
												{custo && <Text style={styles.larguraTexto}>Vlr total:</Text>}
											</View>
											{data.produtos[key].dados.map((item, index) => {
												return (
													<View key={index} style={styles.tableContent} wrap={false}>
														<Text style={styles.larguraTexto}>{formatDate(item.data)}</Text>
														<Text style={styles.larguraTexto}>{item.produto}</Text>
														<Text style={styles.larguraTexto}>{item.quantidade}</Text>
														<Text style={styles.larguraTexto}>{item.unidade}</Text>
														<Text style={styles.larguraTexto}>
															{item.volumeCalda !== 0 ? item.volumeCalda : "-"}
														</Text>
														<Text style={styles.larguraTexto}>{item.modo}</Text>
														{custo && <Text style={styles.larguraTexto}>{item.vlrUnit.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
														})}</Text>}
														{custo && (
															<Text style={styles.larguraTexto}>{item.vlrTotal.toLocaleString("pt-BR", {
																style: "currency",
																currency: "BRL",
															})}</Text>
														)}
													</View>
												);
											})}
										</View>

										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<Text>Total:</Text>
												<View style={styles.containerNumberTotal}>
													{custo && (
														<Text>{data.produtos[key].total.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
														})}</Text>
													)}
												</View>
											</View>
										</View>
									</View>
								);
							})}
						</View>
						<View style={styles.Body}>
							<View style={styles.mainTitle} wrap={false}>
								<Text>Atividades Realizadas</Text>
							</View>
							{Object.keys(data.atividades).map((key, index) => {
								return (
									<View key={index}>
										<View style={styles.Subtitle} wrap={false}>
											<Text>{key}</Text>
											{/* {custo && <Text>TOTAL:5000</Text>} */}
										</View>
										<View style={styles.content}>
											<View style={styles.tableTitle} wrap={false}>
												<Text style={styles.larguraTexto}>data:</Text>
												<Text style={styles.larguraTexto}>serviço:</Text>
												<Text style={styles.larguraTexto}>Hora de Aplicação.</Text>
												<Text style={styles.larguraTexto}>Duração:</Text>
												<Text style={styles.larguraTexto}>Numero de operadores:</Text>
												<Text style={styles.larguraTexto}>operador:</Text>
												{custo && <Text style={styles.larguraTexto}>Vlr Unt:</Text>}
												{custo && <Text style={styles.larguraTexto}>Vlr total:</Text>}
											</View>
											{data.atividades[key].dados.map((item, index) => {
												return (
													<View key={index} style={styles.tableContent} wrap={false}>
														<Text style={styles.larguraTexto}>{formatDate(item.data)}</Text>
														<Text style={styles.larguraTexto}>{item.servico}</Text>
														<Text style={styles.larguraTexto}>{formatTime(item.horaAplicacao)}</Text>
														<Text style={styles.larguraTexto}>{convertToHours(item.duracao)}</Text>
														<Text style={styles.larguraTexto}>{item.numeroOperadores}</Text>
														<Text style={styles.larguraTexto}>{item.operador}</Text>
														{custo && <Text style={styles.larguraTexto}>{item.vlrUnit.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
														})}</Text>}
														{custo && (
															<Text style={styles.larguraTexto}>{item.vlrTotal.toLocaleString("pt-BR", {
																style: "currency",
																currency: "BRL",
															})}</Text>
														)}
													</View>
												);
											})}
										</View>
										<View style={styles.containerTotalTalhao} wrap={false}>
											<View style={styles.containerTotalTalhaoGrey}>
												<Text>Total:</Text>
												<View style={styles.containerNumberTotal}>
													{custo && (
														<Text>{data.atividades[key].total.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
														})
														}</Text>
													)}
												</View>
											</View>
										</View>
									</View>
								);
							})}
						</View>
						<View style={styles.Body}>
							<View style={styles.mainTitle}>
								<Text>Registro de colheita</Text>
							</View>
							{data.colheitas.length > 0 && (
								<>
									<View style={styles.Subtitle}>
										<Text>Colheitas</Text>
									</View>
									<View style={styles.content}>
										<View style={styles.tableTitle} wrap={false}>
											<Text style={styles.larguraTexto}>data:</Text>
											<Text style={styles.larguraTexto}>Operador:</Text>
											<Text style={styles.larguraTexto}>Lote.</Text>
											<Text style={styles.larguraTexto}>Quant.:</Text>
										</View>
										{data.colheitas.map((item, index) => {
											return (
												<View key={index} style={styles.tableContent} wrap={false}>
													<Text style={styles.larguraTexto}>{formatDate(item.data)}</Text>
													<Text style={styles.larguraTexto}>{item.operador}</Text>
													<Text style={styles.larguraTexto}>{item.lote}</Text>
													<Text style={styles.larguraTexto}>{item.quantidade}{item.unidade}</Text>
												</View>
											);
										})}
									</View>
								</>
							)}
						</View>
						<View style={styles.footer} fixed>
							<View style={styles.dividerFooter}></View>
							<View style={styles.containerFooter}>
								<Image src={LogoHorizontal} style={styles.logoFooter} />
								<View style={styles.paggingContainer}>
									<View style={styles.paggingText}>
										<Text style={{ fontFamily: "Roboto bold" }}>
											https://sisagri.com.br
										</Text>
										<Text>
											{date + " - " + hora + " - " + (user?.name ?? "Não informado")}
										</Text>
									</View>
									<Text
										style={styles.pagnumber}
										render={({ pageNumber }) => `${pageNumber}`}
									/>
								</View>
							</View>
						</View>
					</Page>
				</Document>
				: <Document></Document>
		);
	}
	return geraDocumento();
}

export default React.memo(PdfCadernoCampo);
