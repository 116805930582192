import { PropertyInputRepository } from "../repository/property_input_repository";

export class PropertyInputService {

	constructor() {
		this.propertyInputRepository = new PropertyInputRepository();
	}

	async get(propertyId, params) {
		const propertyInputs = await this.propertyInputRepository.get(propertyId, params);
		return propertyInputs.inputs;
	}

	async getById(id) {
		const propertyInput = await this.propertyInputRepository.getById(id);
		return propertyInput;
	}

	async save(propertyInput) {
		return await this.propertyInputRepository.save(propertyInput);
	}

	async update(propertyInput) {
		return await this.propertyInputRepository.update(propertyInput);
	}

	async delete(id) {
		return await this.propertyInputRepository.delete(id);
	}
}