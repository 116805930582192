import { Scheduler } from "@aldabil/react-scheduler";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { RESOURCES } from "./data";
// import { EVENTS, RESOURCES } from "./data";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import styles from "./HomeCalender.module.css";

import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";
import "./styles.css";
import { ManagementService } from "../../service/management_service";
import { HarvestService } from "../../service/harvest_service";

function HomeCalender() {
	const [data, setData]= useState([]);
	const calendarRef = useRef(Scheduler);

	const managementService = new ManagementService();
	const harvestService = new HarvestService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const managements = await getManegementDemands();
		const demands = await getHarvestDemands();
		const forecast = await getHarvestForecasts();		

		const activities = managements.concat(demands).concat(forecast);
		criaData(activities);
	};
	
	const criaData = (data) => {
		const updatedDataList = [];
		const dateMap = {};

		data.forEach(item => {
			const newItem = { ...item };
			const startDateString = newItem.start.toString();
			// const endDateString = newItem.end.toString();

			if (dateMap[startDateString]) {
				newItem.start.setHours(newItem.start.getHours() + dateMap[startDateString]);
				newItem.end.setHours(newItem.end.getHours() + dateMap[startDateString]);
				dateMap[startDateString]++;
			} else {
				dateMap[startDateString] = 1;
			}

			// if (dateMap[endDateString]) {
			// 	newItem.end.setHours(newItem.end.getHours() + dateMap[endDateString]);
			// 	dateMap[endDateString]=1;
			// } else {
			// 	dateMap[endDateString] = 1;
			// }
			updatedDataList.push(newItem);
		});
		const newData = updatedDataList.map((i) => {
			let item = {
				id: i.id,
				event_id: 1,
				admin_id: 1,
				categoria: i.categoria,
				title: i.title,
				start: i.start,
				end: i.end,
			};
			return item;
		});
		
		const dataEnd = newData.concat(data);
		
		setData(dataEnd);
	};

	const getManegementDemands = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");

		const params = {
			page: 1,
			limit: 1000,
			filterValue: encodeURI(JSON.stringify(
				{ 
					pendente: true,
					responsavel: user.id
				}
			))
		};

		const managements = await managementService.getByProperty(propertyId, params);

		const managementWithDetails = [];
		for (let i = 0; i < managements.length; i++) {
			const management = managements[i];
			const cropSeason = management.safra;
			const plot = cropSeason.talhao;
			const propertyFarm = cropSeason.propriedadeCultivo;
			const farm = propertyFarm.cultivo;
			const subdivision = cropSeason.subdivisao;
			const propertyService = management.propriedadeServico;
			const service = propertyService ? propertyService.servico : null;
		
			const startDate = new Date(management.dataAplicacao);
			startDate.setTime(startDate.getTime() + 10800000);
			startDate.setHours(8);
			const finalDate = new Date(management.dataAplicacao);
			finalDate.setTime(finalDate.getTime() + 10800000);
			finalDate.setHours(9);

			let title = "";
			if (subdivision) {
				const catSub = subdivision.categoriaSubdivisao;
				title =  `${service? service.nome : ""} - Talhão ${plot.nome} ${
					subdivision !== undefined ? catSub + " " + subdivision?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")}`;
			}
			else {
				title =  `${service? service.nome : ""} - Talhão ${plot.nome} - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")}`;
			}

			let newManejo = {
				id: management.id,
				event_id: 2,
				admin_id: 2,
				categoria:2,
				title: title,
				start: startDate,
				end: finalDate,
			};
			managementWithDetails.push(newManejo);
		}
		return managementWithDetails;
	};

	const getHarvestDemands = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");

		const params = {
			page: 1,
			limit: 1000,
			filterValue: encodeURI(JSON.stringify(
				{ 
					pendente: true,
					responsavelColheita: user.id,
					previsao: false
				}
			))
		};

		const harvests = await harvestService.getByProperty(propertyId, params);

		const harvestsDetails = [];
		for (let i = 0; i < harvests.length; i++) {
			const harvest = harvests[i];
			const PropertyFarm = harvest.propriedadeCultivo;
			const farmPackage = harvest.embalagem;
			const measurementUnit = farmPackage.unidadeMedida;
			const farm = await PropertyFarm.cultivo;

			const startDate = new Date(harvest.dataColheita);
			startDate.setTime(startDate.getTime() + 10800000);
			startDate.setHours(8);
			const finalDate = new Date(harvest.dataColheita);
			finalDate.setTime(finalDate.getTime() + 10800000);
			finalDate.setHours(9);

			let newColheita = {
				id: harvest.id,
				event_id: 3,
				admin_id: 3,
				categoria:3,
				
				title: `Colheita - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")} qte: ${harvest.quantidade} ${measurementUnit.sigla}`,
				start: startDate,
				end: finalDate,
			};
			harvestsDetails.push(newColheita);
		}
		return harvestsDetails;
	};

	const getHarvestForecasts = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const params = {
			page: 1,
			limit: 1000,
			filterValue: encodeURI(JSON.stringify(
				{
					previsao: true
				}
			))
		};

		const harvests = await harvestService.getByProperty(propertyId, params);

		const harvestsDetails = [];
		for (let i = 0; i < harvests.length; i++) {
			const harvest = harvests[i];
			const cropSeasons = harvest.loteSafras;
			const cropSeason = cropSeasons[0];
			const safraname = await getCropSeasonName(cropSeason.safra);
			
			const startDate = new Date(harvest.dataPrevista);
			startDate.setTime(startDate.getTime() + 10800000);
			startDate.setHours(8);
			const finalDate = new Date(harvest.dataPrevista);
			finalDate.setTime(finalDate.getTime() + 10800000);
			finalDate.setHours(9);

			let newColheita = {
				id: harvest.id,
				event_id: 4,
				admin_id: 4,
				categoria:4,
				title: safraname,
				start: startDate,
				end: finalDate,
			};
			harvestsDetails.push(newColheita);
		}
		return harvestsDetails;
	};
	
	const getCropSeasonName = async (cropSeason) => {
		const plot = cropSeason.talhao;
		let cropSeasonName = `Talhão ${plot.nome}`;
		if (cropSeason.subdivisao) {
			const subdivision = cropSeason.subdivisao;
			cropSeasonName += ` - ${subdivision.categoriaSubdivisao} ${subdivision.complemento}`;
		}
		const propertyFarm = cropSeason.propriedadeCultivo;
		const farm = propertyFarm.cultivo;
		cropSeasonName += ` - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")}`;
		return cropSeasonName;
	};

	function geraComponente() {
		return (
			<Box>
				<Scheduler
					// height={200}
					ref={calendarRef}
					locale={ptBR}
					editable={false}
					deletable={false}
					draggable={false}
					events={data}
					resources={RESOURCES}
					resourceViewMode="tabs"
					// onEventClick={(e) => console.log(e)}
					day={{
						startHour: 8, 
						endHour: 17, 
						step: 60,
						navigation: true,
						cellRenderer:() => {},
					}}
					week={{ 
						weekDays: [0, 1, 2, 3, 4, 5,6], 
						weekStartOn: 0, 
						startHour: 8, 
						endHour: 17,
						cellRenderer:() => {},
						step: 60,
						navigation: true,
						disableGoToDay: false
					}}
					month={{
						weekDays: [0, 1, 2, 3, 4, 5,6], 
						weekStartOn: 0, 
						startHour: 8, 
						endHour: 17,
						cellRenderer:() => {},
						navigation: true,
						disableGoToDay: false
					}}
					resourceFields={{
						idField: "admin_id",
						textField: "title",
						avatarField: "title",
						colorField: "color"
					}}
					translations={{
						navigation: {
							month: "Mês",
							week: "Semana",
							day: "Dia",
							today: "Hoje"
						},
						form: {
							confirm: "Confirmar",
							delete: "Deletar",
							cancel: "Cancelar"
						},
						event: {
							title: "Title",
							start: "Start",
							end: "End",
							allDay: "All Day"
						},
			
						moreEvents: "More...",
						loading: "Loading..."
					}}
					fields={[
						{
							name: "admin_id",
							type: "input" , 
							default: RESOURCES[0].admin_id,
							options: RESOURCES.map((res) => {
								return {
									id: res.admin_id,
									text: `${res.title}`,
									value: res.admin_id
								};
							}),
							config: { label: "a", required: true }	
						}
					]}
					viewerExtraComponent={(fields, event) => {
						return (
							<div>
								{fields.map((field, i) => {
									if (field.name === "admin_id") {
										const admin = field.options.find(
											(fe) => fe.id === event.admin_id
										);
										if (event.categoria === 2) return<div key={i} className={styles.btnContainer}> <Link to={`/manejo/cadastroManejo?confirm=s&id=${event.id}`} className={styles.btn}>Finalizar</Link></div>;
										if(event.categoria === 3)	return <div key={i} className={styles.btnContainer}> <Link to={"/colheita"} className={styles.btn}>Finalizar</Link></div>;
										if (event.categoria === 4) return <div key={i} className={styles.btnContainer}> <Link to={"/colheita"} className={styles.btn}>Finalizar</Link></div>;
										return (
											<Typography
												key={i}
												style={{ display: "flex", alignItems: "center" }}
												color="textSecondary"
												variant="caption"
												wrap
											>
												{admin.text}
											</Typography>
										);
									} else {
										return "";
									}
								})}
							</div>
						);
					}}
				/>
			</Box>);
	}
	
	return geraComponente(); 
	
}

export default HomeCalender;
