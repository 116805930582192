/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import * as locales from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import ButtonTable from "../Buttons/buttonTable";

import {
	Box,
	createTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Slide,
	TableSortLabel,
	ThemeProvider,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import styles from "./TableGenerator.module.css";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
					
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function TableGenerator(props) {
	const data = props.data; //array de objetos
	const editHref = props.editHref; //string
	const [selected, setSelected] = useState([]);
	const [open, setOpen] = useState(false); //modal
	const [id, setId] = useState("");
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const keys = props.columns; //array de chaves
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(keys[0].id);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = data.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const handleOpen = (e) => {
		if (props.onclickRow !== undefined) {
			if (e.target.id !== undefined) setId(e.target.id); //seta o id do elemento clicado para abrir o dialog
			setOpen(true); //abre o modal
		} else {
			return;
		}
	};

	const handleClose = () => {
		setOpen(false); //fecha o modal
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function comparacaoOrderByDate(o) {
		if (
			o === "data_inicio" ||
			o === "data" 			
		) 
			return true;
		
		else 
			return false;
		
	}
	function descendingComparator(a, b, orderBy) {
	
		if (comparacaoOrderByDate(orderBy)) {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(data, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, data]
	);
	return (
		<>
			<Paper sx={{ width: "100%" }}>
				<ThemeProvider theme={themeWithLocale}>
					{props.children}
					<TableContainer sx={{ maxHeight: "75vh" }}>
						<Table stickyHeader>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClicks}
								onRequestSort={handleRequestSort}
								rowCount={data.length}
								columns={keys}
							/>
							<TableBody>
								{
									//cria uma linha por objeto
									visibleRows
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow
													hover
													id={row.id}
													tabIndex={-1}
													key={row.id}
													sx={{ height: 3 }}
												>
													{keys.map((column, index) => {
														const aux = row[column.id].toString().split(";");
														let auxId = row.id;
														if (aux.length > 1) {
															return (
																<TableCell key={index} id={row.id}>
																	{aux.map((row, index) => {
																		return (
																			<TableCell
																				key={index}
																				id={auxId}
																				onClick={handleOpen}
																				sx={{
																					display: "flex",
																					flexDirection: "column",
																					p: 1,
																					border: "none",
																				}}
																			>
																				{row}
																			</TableCell>
																		);
																	})}
																</TableCell>
															);
														} else {
															return (
																<TableCell
																	key={index}
																	id={row.id}
																	onClick={handleOpen}
																>
																	{aux}
																</TableCell>
															);
														}
													})}

													<TableCell>
														{props.menuTable === "disable" ? null : (
															<ButtonTable
																className={styles.btn}
																editHref={`${editHref}${row.id}`} //editHref={props.editHref row.id}
																deletFunc={props.handleDelete}
																rowId={row.id}
																optionSettings={props.optionSettings}
																custonOpition={props.custonOpition} // determina quais opções irao aparecer
															/>
														)}
													</TableCell>
												</TableRow>
											);
										})
								}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[7, 14, 21]}
						component="div"
						colSpan={7}
						count={visibleRows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						labelRowsPerPage="Linhas por pagina"
						SelectProps={{
							inputProps: {
								"aria-label": "rows per page",
							},
							native: false,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</ThemeProvider>
			</Paper>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Deseja visualizar mais detalhes do item?
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
					<button className={styles.ButtonCancelarModal} onClick={handleClose}>
						Cancelar
					</button>

					<Link to={`${props.onclickRow}/${id}`}>
						<button className={styles.ButtonColherModal}>ver</button>
					</Link>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default TableGenerator;
