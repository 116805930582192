import React, { useEffect, useState } from "react";
import logo from "../../Assets/images/logo.svg";
import styles from "./Login.module.css";

// icons import
import HttpsIcon from "@mui/icons-material/Https";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AuthService } from "../../service/auth_service";

//material ul imports
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { PropertyService } from "../../service/property_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function Login() {
	let [showPassword, setShowPassword] = useState(true);
	let [confirmationNeeded] = useState(false);
	const [user, setUser] = useState("");
	const [senha, setSenha] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [carregandoLogin, setCarregandoLogin] = useState(false);

	useEffect(() => {
	}, []);

	function handleClick() {
		setShowPassword(!showPassword);
		if (showPassword) document.getElementById("password").type = "text";
		else document.getElementById("password").type = "password";
	}

	const confirm = async (e) => {
		setCarregandoLogin(true);
		e.preventDefault();

		const authService = new AuthService();
		const propertyService = new PropertyService();

		try {
			await authService.authenticate(user, senha);
			const profile = await authService.getProfile();
			const userProperties = await propertyService.getByUser(profile.userId);
			if(userProperties.length > 0){
				localStorage.setItem("propertyId", userProperties[0].propriedade.id);
				window.location.href = "/home";
			} else {
				window.location.href = "/propriedade";
			}
		} catch (error){
			setErrorMsg(error.message);
		} finally {
			setCarregandoLogin(false);
		}
	};

	//hltm login
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "ceter",
					alignItems: "center",
					bgcolor: "white",
					p: 0,
					m: 0,
				}}
			>
				<Box
					sx={{
						p: 0,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "ceter",
							alignItems: "center",
							flexDirection: {
								xs: "column-reverse",
								sm: "column-reverse",
								md: "row",
							},
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "ceter",
								alignItems: "center",
							}}
						>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100%",
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									sx={{ px: { xs: 3, sm: 3, md: 2 } }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											sx={{
												with: "100%",
												height: "100% ",
												border: "none",
												boxShadow: "none",
											}}
										>
											<img className={styles.logo} src={logo} />
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											className={styles.loginTextContainer}
											sx={{ width: "100%", border: "none", boxShadow: "none" }}
										>
											<h2>Rastreabilidade vegetal e gestão agrícola</h2>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
						{/* form  */}
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								background: "linear-gradient(180deg, #1A877F 0%, #12B6AA 100%)",
							}}
						>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100vh",
									p: { xs: 5, sm: 10, md: 15, lg: 20 },
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											m: 0,
											height: "100%",
										}}
									>
										<Item sx={{ border: "none", boxShadow: "none" }}>
											<h2 className={styles.title}>Acesse sua conta</h2>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemError}>{errorMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<PersonIcon className={styles.icon} />
														<input
															className={styles.input}
															type="text"
															placeholder="Usuario"
															value={user}
															onChange={(e) => setUser(e.target.value)}
															onKeyUp={(e) => {
																e.key === "Enter" || e.keyCode === 13
																	? confirm(e)
																	: "";
															}}
														/>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<HttpsIcon className={styles.icon} />
														<input
															id="password"
															className={styles.input}
															type="password"
															placeholder="senha"
															value={senha}
															onChange={(e) => {
																setSenha(e.target.value);
															}}
															onKeyUp={(e) => {
																e.key === "Enter" || e.keyCode === 13
																	? confirm(e)
																	: "";
															}}
														/>
														<div onClick={handleClick}>
															{showPassword ? (
																<VisibilityIcon
																	className={styles.iconPassword}
																/>
															) : (
																<VisibilityOffIcon
																	className={styles.iconPassword}
																/>
															)}
														</div>
													</Item>
												</Grid>
												{confirmationNeeded && (
													<Grid item xs={12} sm={12} md={12}>
														<Item
															className="formInput"
															sx={{ border: "none", boxShadow: "none" }}
														>
															<input
																id="code"
																type="text"
																className={styles.input}
																placeholder="Código de verificação"
															/>
														</Item>
													</Grid>
												)}
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<Grid
															container
															rowSpacing={1}
															columnSpacing={{ xs: 0, sm: 2, md: 3 }}
														>
															<Grid item xs={12} sm={12} md={12}>
																<Item
																	sx={{
																		border: "none",
																		boxShadow: "none",
																		display: "flex",
																		mt: -4,
																		justifyContent: "end",
																	}}
																>
																	<a
																		className={styles.ForgotPasswordLink}
																		href="/Login/recuperar-senha"
																	>
																		Esqueceu sua senha?
																	</a>
																</Item>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<Item
																	sx={{
																		border: "none",
																		boxShadow: "none",
																		display: "flex",
																		mt: -2,
																		justifyContent: "start",
																	}}
																>
																	<input type="checkbox" id="remember" />
																	<label htmlFor="remember">
																		Mantenha-me conectado
																	</label>
																</Item>
															</Grid>
														</Grid>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														{!carregandoLogin ? (
															<button
																className={styles.buttonLogin}
																type="submit"
																onClick={confirm}
															>
																Login
															</button>
														) : (
															<button className={styles.buttonLoginCarregando}>
																<div className={styles.ldsroller}>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																</div>
																{/* Login */}
															</button>
														)}
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p>Não possui uma conta?</p>
														<a className={styles.link} href="https://wa.me/5585981310242?text=Ol%C3%A1%2C%20estou%20no%20site%20sisagri%20e%20gostaria%20de%20solicitar%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20solu%C3%A7%C3%A3o">
															Solicitar cadastro
														</a>
													</Item>
												</Grid>
											</Grid>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Login;
