//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

// import styles from "./Occurrences.module.css";

//import components
import Search from "../../components/SearchHeader";
import InputSearch from "../../components/SearchHeader/inputSeach";
import Table from "../../components/TableGenerator";
import TopMenu from "../../components/TopMenu";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";

import "../CommonStyle/style.css";
import { OccurrencesService } from "../../service/occurrences_service";
import { CropSeasonService } from "../../service/crop_season_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id: "category",
		label: "Categoria",
	},
	{
		id: "type",
		label: "Tipo",
	},
	{
		id: "safra",
		label: "Safra",
	},
];
function Occurrences() {
	const [search, setSearch] = useState("");
	const [allOccurrences, setAllOccurrences] = useState([]);

	const occurrencesService = new OccurrencesService();
	const cropSeasonService = new CropSeasonService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const occurrences = await occurrencesService.getByOccurrencesCropSeason(propertyId, {});
		setAllOccurrences(await getOccurrences(occurrences));
	};

	const getOccurrences = async (occurrencesInSafra) => {
		const occurrences = [];
		for (var i = 0; i < occurrencesInSafra.length; i++) {
			const occurrenceInSafra = occurrencesInSafra[i];
			const occurrence = occurrenceInSafra.ocorrencia;
			const safraName = await getSafraName(occurrenceInSafra.safra.id);
			const typeOccurrence = occurrence.tipoOcorrencia;
			const categoryOccurrence = typeOccurrence.categoriaOcorrencia;

			occurrences.push({
				id: occurrence.id,
				itemId: occurrence.id,
				safra: safraName,
				type: typeOccurrence.nome,
				category: categoryOccurrence.nome,
			});
		}
		return occurrences;
	};

	const getSafraName = async (safraId) => {
		const safra = await cropSeasonService.getById(safraId);
		const talhao = safra.talhao;
		let safraname = `Talhão ${talhao.nome}`;
		if (safra.subdivisaoId !== null && safra.subdivisaoId !== undefined) {
			const subdivisao = safra.subdivisao;
			const subdivisaoCategory = subdivisao.categoriaSubdivisao;
			safraname += ` - ${captalizeFirstLetterEacheWord(subdivisaoCategory)} ${subdivisao.complemento}`;
		
		}
		const propertyCulture = safra.propriedadeCultivo;
		const cultivo = propertyCulture.cultivo;
		safraname += ` - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`;
		return safraname;
	};

	const dataFilter = allOccurrences.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}
	const handleDelete = async (id) => {
		await occurrencesService.delete(id);
		await initData();
	};
	const custonOp = [
		{
			item: "Exportar Pdf",
			func: function handleOpen(e) {
				return (window.location.href = `/pdf/?pag=OcorrenciaDetalhada&id=${e}`);
			},
		},
	];
	return (
		<div>
			<TopMenu open={["launch", "occurrences"]} help="occurrences" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Ocorrências"
									href="ocorrencias/cadastroOcorrencia"
									textButton="Nova ocorrência"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									editHref="/ocorrencias/cadastroOcorrencia?id="
									handleDelete={handleDelete}
									custonOpition={custonOp}
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Occurrences;
